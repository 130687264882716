@import "../../../assets/styles/global";


.my-tickets-page {
  @include containerWithPadding;

  .tickets-filter-box {
    @include spaceBetween;
    margin: 24px 0;
    @media (min-width: $tablet) {
      margin: 32px 0;
    }
    @media (min-width: $desktop) {
      margin: 40px 0;
    }
  }

  .tickets-date-range {
    @include alignCenter;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #D0D2D7;
    background: #FFF;
    height: 48px;

    p {
      color: rgba(34, 34, 34, 0.80);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    svg {
      filter: invert(0.5);
    }
  }

  .ticket-type-box {
    width: 100%;
    max-width: 343px;
    height: 44px;
    @include flexCenter;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid $borderColor1;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    padding: 4px;

    @media (min-width: $desktop) {
      height: 48px;
    }

    button {
      @include flexCenter;
      @include resetBtn;
      width: 100%;
      height: 100%;
      color: $textColor5;
      font-family: Inter,sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      border-radius: 8px;
    }

    .active {
     color: $white;
      background: $brandPrimary;
    }

    .inactive {

    }
  }

  .ticket-box {
    border-radius: 8px;
    border: 1px solid $borderColor6;
    background: $white;
    padding: 12px 16px;

    @media (min-width: $tablet) {
      padding: 16px 20px;
    }
  }

  .ticket-image-box {
    @include flexCenter;
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 8px;
    background: $backgroundColor9;
    margin-right: 12px;
    padding: 20px;
    overflow: hidden;

    @media (min-width: $tablet) {
      width: 80px;
      min-width: 80px;
      height: 80px;
      margin-right: 16px;
      padding: 28px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (min-width: $tablet) {
        width: 80px;
        height: 80px;
      }
    }
  }

  .ticket-image-information {
    @include spaceBetween;
    align-items: flex-start;
    padding: 0 0 8px 0;

    @media (min-width: $tablet) {
      padding: 0 0 12px 0;
    }
  }

  .download-ticket-btn {
    @include resetBtn;
    @include flexCenter;
    width: 44px;
    height: 44px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $borderColor1;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  }

  .ticket-texts-box {

    .full-width {
      color: $textColor1;
      font-family: Inter,sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 4px;

      @media (min-width: $tablet) {
        font-size: 16px;
      }
    }

    .ticket-desc {
      color: rgba(34, 34, 34, 0.80);
      font-size: 14px;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .ticket-date-box {
    @include spaceBetween;
    border-top: 1px solid $borderColor6;
    padding: 8px 0 0;
    @media (min-width: $tablet) {
      padding: 12px 0 0;
    }
  }

  .ticket-desktop-view {
    display: none;
    @media (min-width: $tablet) {
      display: block;
    }
  }

  .ticket-mobile-view {
    margin-top: 4px;
    @include spaceBetween;
    @media (min-width: $tablet) {
      display: none;
    }
  }

  .ticket-display-id {
    color: $textColor1;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

    border-radius: 8px;
    padding: 10px 12px;
    background: $backgroundColor4;
  }

  .ticket-date-address {
    @include alignCenter;
  }

  .ticket-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    border-right: 1px solid $borderColor6;
    padding: 0 8px 0 0;
    margin-right: 8px;


    @media (min-width: $tablet) {
      font-size: 18px;
      margin-right: 16px;
      padding: 0 16px 0 0;
    }

    p {
      color: $textColor1;
      text-align: center;
      font-family: Inter,sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    span {
      color: $textColor1;
      text-align: center;
      font-family: Inter,sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .ticket-address {
    display: flex;
    flex-direction: column;

    .day-time {
      color: #000;
      font-size: 16px;
      font-weight: 400;

      span {
        margin-left: 4px;
        font-weight: 600;
      }
    }

    .event-address {
      color: $textColor17;
      font-family: Inter,sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-top: 4px;
    }
  }

  .ticket-body-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 70vh;
    overflow: auto;
  }
}