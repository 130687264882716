@import '../../../assets/styles/global.scss';

.org-details-page {

  .org-details-by-padding {
    @include containerWithPadding;
    padding: 32px 0 0;

    @media (min-width: $tablet) {
      padding: 40px 40px 0;
    }
    @media (min-width: $desktop) {
      padding: 40px 80px 0;
    }

  }

  .org-information-wrapper {
    background: $white;
    padding: 16px;

    @media (min-width: $tablet) {
      padding: 16px 32px;
    }
    @media (min-width: $desktop) {
      padding: 16px 64px 20px;
    }
  }

  .org-images-slider-box {
    .slick-slide img {
      height: 180px;
      object-fit: cover;

      @media (min-width: $tablet) {
        height: 280px;
      }
    }

    .slick-next {
      right: 25px;
    }

    .slick-prev {
      z-index: 10;
      left: 5px;
    }
  }

  .org-information {
    @include containerNoPadding;
  }

  .org-information-box {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 12px;

    @media (min-width: $tablet) {
      gap: 16px;
    }

    @media (min-width: $tablet) {
      gap: 20px;
    }

    p {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @media (min-width: $tablet) {
        font-size: 28px;
      }
      @media (min-width: $tablet) {
        font-size: 38px;
      }
    }
  }

  .org-avatar-box {
    @include flexCenter;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.10);

    @media (min-width: $tablet) {
      width: 56px;
      height: 56px;
    }
    @media (min-width: $desktop) {
      width: 64px;
      height: 64px;
    }

    img {
      object-fit: cover;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      @media (min-width: $tablet) {
        width: 56px;
        height: 56px;
      }
      @media (min-width: $desktop) {
        width: 80px;
        height: 80px;
      }
    }
  }

  .org-name {
    color: $black;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (min-width: $tablet) {
      font-size: 28px;
    }
    @media (min-width: $desktop) {
      font-size: 34px;
    }
  }

  .org-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;

    @media (min-width: $tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: $desktop) {
      padding-left: 84px;
      margin-top: 4px;
    }
  }

  .org-categories-and-hours {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .org-categories-wrapper {
    @include alignCenter;
    flex-wrap: wrap;
  }

  .org-categories-box {
    @include alignCenter;

    p {
      color: #222;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .org-categories-box:last-of-type {
    .categories-circle {
      display: none;
    }
  }

  .categories-circle {
    width: 5px;
    height: 5px;
    border-radius: 40px;
    margin: 0 8px;
    background-color: #535862;
  }

  .org-today-hour {
    display: flex;
    align-items: center;
    gap: 16px;

    .closed {
      color: $brandPrimary;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px
    }

    .today-working-hour {
      color: #3D4751;
      font-size: 14px;
      font-weight: 500;
    }

    .see-hours {
      color: #3D4751;
      font-size: 14px;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }

  .website-link {
    @include alignCenter;
    gap: 8px;
    color: $textColor2;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .follow-claim-btns {
    width: 100%;
    display: flex;
    gap: 10px;
    border-top: 1px solid $borderColor6;
    padding-top: 16px;
    margin-top: 16px;

    @media (min-width: $tablet) {
      width: auto;
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    button {
      width: 100%;
      height: 44px;
      @include flexCenter;
      gap: 8px;

      color: $textColor8;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;

      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      padding: 10px 18px;
      transition: all .2s;

      @media (min-width: $tablet) {
        width: 164px;
        padding: 12px 20px;
      }

      @media (min-width: $desktop) {
        width: 168px;
        height: 48px;
      }

      &:hover {
        border: 1px solid $borderColor1;
        background: $backgroundColor4;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      }

      svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
      }

      .MuiCircularProgress-svg {
        width: 18px;
        min-width: 18px;
        height: 18px;
      }


      &.following-claim-wrapper {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $borderColor3;
        background: $borderColor3;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        color: $brandPrimary2;

        @media (min-width: $tablet) {
          width: 138px;
        }

        @media (min-width: $desktop) {
          width: 142px;
        }

        svg {
          min-width: 20px;
          height: 20px;
          filter: brightness(0) saturate(100%) invert(19%) sepia(43%) saturate(2123%) hue-rotate(323deg) brightness(94%) contrast(97%);
        }

        .MuiCircularProgress-svg {
          width: 20px;
          min-width: 20px;
          height: 20px;
        }

        &:hover {
          border: 1px solid $backgroundColor13;
          background: $backgroundColor13;
          box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        }
      }

      &.follow-claim-wrapper {
        width: 100%;
        border: 1px solid $brandPrimary;
        background: $brandPrimary;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        color: $white;

        @media (min-width: $tablet) {
          width: 114px;
        }

        @media (min-width: $desktop) {
          width: 118px;
        }

        svg {
          min-width: 20px;
          height: 20px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(181deg) brightness(103%) contrast(103%);
        }

        .MuiCircularProgress-svg {
          width: 20px;
          min-width: 20px;
          height: 20px;
        }


        &:hover {
          border: $brandPrimary2;
          background: $brandPrimary2;
        }
      }
    }
  }

  .claim-is-pending {
    width: 100%;
    @include alignCenter;
    font-size: 16px;
    font-weight: 600;
    gap: 8px;

    p {
      color: $textColor8;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }
  }


  .org-white-box {
    @include whiteBox;
    margin-bottom: 12px;
  }

  .white-box-title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .review-box {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .review-box:first-of-type {
    margin-bottom: 16px;
  }

  .review-title {
    color: #181D27;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 100px;
  }

  .review-info-box {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      color: #181D27;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px
    }

    button {
      @include resetBtn;
      color: #535862;
      font-size: 16px;
      font-weight: 600;
      text-decoration-line: underline;
      padding: 0;
    }
  }

  .org-details-section {

    display: flex;
    flex-direction: column;
    @media (min-width: $desktop) {
      flex-direction: row;
      gap: 32px;
    }
  }

  .org-box-wrapper {
    width: 100%;
    @media (min-width: $desktop) {
      width: 50%;
    }
  }

  .org-socials {
    @include alignCenter;
    gap: 16px;
    margin-top: 8px;

    svg {
      filter: brightness(0) saturate(100%) invert(46%) sepia(3%) saturate(1056%) hue-rotate(177deg) brightness(99%) contrast(96%);
    }
  }

  .org-info-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px 0;
    border-bottom: 1px solid #D4D4D4;
    padding-bottom: 16px;
  }

  .org-info-details-box {
    display: flex;
    align-items: center;
    gap: 8px;

    p, a {
      color: $textColor2;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }

    a {
      color: $textColor1;
      text-decoration: none;
    }

    .svg-box {
      width: 20px;
    }

    svg {
      filter: brightness(0%);
      width: 20px;
    }
  }

  .org-details-wrapper {
    margin: 0 0 0;
    @media (min-width: $tablet) {
      margin: 16px 0 0;
    }
  }

  .org-description {
    color: $textColor2;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .org-created-date {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #222;
    font-size: 14px;
    font-weight: 400;
  }

  .org-days-hours {
    margin: 12px 0;

    .org-days-hours-box {
      @include alignCenter;
      gap: 24px;
      margin-bottom: 12px;
    }

    .org-work-week {
      width: 35px;

      color: $textColor1;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      text-transform: uppercase;
    }

    .org-work-hours {
      color: $textColor2;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .org-work-closed {
      color: $borderColor2;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .map-title {
    color: $textColor2;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 12px;

    @media (min-width: $tablet) {
      margin-top: 8px;
    }
  }

  .org-sections-wrapper {
    padding-bottom: 48px;

    @media (min-width: $desktop) {
      padding-bottom: 64px;
    }
  }

  .org-sections-box, .org-sections-box-mb-0 {
    @include spaceBetween;
    gap: 10px;
    margin: 32px 0 16px;
    padding: 0 16px;

    @media (min-width: $tablet) {
      margin: 40px 0 24px;
      padding: 0;
    }
    @media (min-width: $desktop) {
      margin: 64px 0 40px;
      padding: 0;
    }

    .org-sections-box-mb-0 {
      margin: 32px 0 0;
      padding: 0 16px;

      @media (min-width: $tablet) {
        margin: 40px 0 24px;
      }
      @media (min-width: $desktop) {
        margin: 64px 0 0;
      }
    }


    .org-detail-sections-title {
      color: $textColor1;
      font-family: Poppins, sans-serif !important;
      font-size: 22px;
      font-weight: 600;

      @media (min-width: $tablet) {
        font-size: 24px;
      }
      @media (min-width: $desktop) {
        font-size: 32px;
      }

    }

    a {
      @include alignCenter;
      gap: 4px;
      color: $brandPrimary2;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-decoration: none;

      svg {
        transition: all .2s;
      }

      &:hover {
        svg {
          transform: rotate(45deg);
          transition: all .2s;
        }
      }
    }
  }

  .similar-orgs {
    max-height: 650px;
    overflow: auto;
  }

}


.org-title-and-edit {
  @include spaceBetween;
  gap: 16px;
  color: $textColor1;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media (min-width: $tablet) {
    font-size: 20px;
    line-height: 27px;
  }

  button {
    @include flexCenter;
    @include resetBtn;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid $borderColor1;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    padding: 8px;
    transition: all .2s;

    @media (min-width: $tablet) {
      width: 40px;
      height: 40px;
      padding: 10px;
    }

    @media (min-width: $desktop) {
      width: 44px;
      height: 44px;
      padding: 12px;
    }

    &:hover {
      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $backgroundColor4;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      transition: all .2s;
    }
  }
}


.review-modal {
  max-width: 700px;
  width: 100%;

  .review-modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #000;

    @media (min-width: $tablet) {
      font-size: 32px;
    }
  }

  .stars-box {
    @include alignCenter;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0 24px;
    gap: 4px;
    @media (min-width: $tablet) {
      font-size: 18px;
      margin: 16px 0 32px;
    }
  }

  .review-card-wrapper {
    height: auto;
    width: 100%;
    background: #FAFAFA 0 0 no-repeat padding-box;
    box-shadow: 0 2px 6px #0000001A;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 8px;
    @media (min-width: $tablet) {
      padding: 24px;
      margin-bottom: 24px
    }
  }

  .review-card-box {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  .reviewer-image {
    min-width: 45px;
    width: 45px;
    height: 45px;
    border-radius: 40px;
    box-shadow: 0 2px 6px #0000001A;
    background: #D9DBDD;
  }

  .reviewer-name {
    font-size: 16px;
    font-weight: 600;
    @media (min-width: $tablet) {
      font-size: 18px;
      margin-bottom: 8px;
    }

  }

  .reviewer-desc {
    font-size: 14px;
    font-weight: normal;
    color: #545F7E;
    @media (min-width: $tablet) {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.org-skeleton{
    width: 100%;
    opacity: 0.3;
    height: 154px!important;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid $borderColor6;
}