@import "../../assets/styles/global";

.layout-wrapper {
  width: 100%;
  background: $white;
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1400;

  .layout-box {
    max-width: 1440px;
    width: 100%;
    padding: 19px 16px 7px;
    margin: 0 auto;
    min-height: 80px;

    @media (min-width: $tablet) {
      padding: 18px 40px;
    }

    @media (min-width: $xl) {
      padding: 18px 60px;
    }
  }

  .layout-box-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo-navigation {
    @include flexCenter;
    gap: 80px;

    .header-logo-button {
      @include resetBtn;
    }

    .header-navigation-desktop {

      @media (max-width: $desktop) {
        display: none;
      }

      .header-link {
        color: $textColor5;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-decoration: none;
        margin-right: 32px;
        border-bottom: 2px solid $white;
        outline: none;

        &.active {
          border-bottom: 2px solid $brandPrimary;
          color: $textColor1;
        }

        &:hover {
          border-bottom: 2px solid $borderColor2;
        }
      }

      .header-link:last-of-type {
        margin-right: 0;
      }
    }

  }

  .header-buttons-box {
    display: flex;
    align-items: center;


    .flex {
      margin-left: 40px;
    }
  }

  .layout-searched-box {

  }

  .show-search-btn {
    @include resetBtn;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    margin-right: 8px;

    path {
      fill: #344054;
    }

    &.show-search-btn-show {
      box-shadow: 0 0 0 4px #F8EBEC;
    }
  }

  //base-Popper-root custom-select-paper-box css-advny6-MuiPopper-root
  #simple-note-popper {
    z-index: 1000;
    background: red;
  }

  .user-info-and-notifications {
    @include alignCenter;
    gap: 16px;
  }

  .loggedIn-header {
    @include alignCenter;
    @include resetBtn;
    font-size: 16px;
    gap: 8px;
    cursor: pointer;
    //margin-left: 48px;
    max-width: 240px;
    min-width: 150px;
    width: 100%;
    padding: 0;

    .full-width {

      p {
        text-align: start;
        color: $textColor7;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
      }
    }

    .loggedIn-user-svg-block {
      min-width: 48px;
      width: 48px;
      height: 48px;
      padding: 10px;
      border-radius: 200px;
      overflow: hidden;
      background: $backgroundColor2;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: $borderColor4;
          stroke-width: 2.33333;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .loggedIn-header-layout {
    //margin-left: 32px;
  }

  .notification-btn {
    @include resetBtn;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-left: 0;

    @media (min-width: $tablet) {
    }

    svg{
      width: 100%;
      height: 100%;
    }
  }
}

.mobile-auth-buttons-box {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;

  .sign-in {
    margin: 0;
  }
}

.header-login-btns {
  gap: 12px;
  margin: 0 !important;
}

.sign-in {
  @include resetBtn;
  color: $textColor5;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  padding: 10px 18px;
}

.sign-up {
  @include resetBtn;
  color: $whiteMain;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  background: $brandPrimary;
  white-space: nowrap;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  padding: 10px 18px;
  transition: all .2s;

  &:hover {
    background: $brandPrimary2;
    transition: all .2s;
  }
}

.sign-out {
  @include alignCenter;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid $mainRed;
  color: $mainRed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 36px;
  padding: 0 20px;
  background: transparent;
}

.desktop-navigation-bar-box {
  display: flex;
  flex-direction: column;

  .desktop-navigation-bar-links-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px;

    button {
      width: 100%;
      height: 44px;
      @include resetBtn;
      @include flexCenter;
      justify-content: flex-start;
      gap: 8px;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid transparent;

      .icon-box {
        width: 20px;
        height: 20px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      p {
        color: $textColor5;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }

      &:hover {
        background: $borderColor5;
        border: 1px solid $borderColor5;
      }

      &.active-desktop-menu-link {
        border: 1px solid $borderColor3;
        background: $borderColor3;

        svg {
          path {
            stroke: $brandPrimary2;
          }
        }

        p {
          color: $brandPrimary2;
        }
      }
    }
  }

  .desktop-logout-button-block {
    padding: 8px 16px;

    .desktop-logout-button {
      width: 100%;
      @include resetBtn;
      @include flexCenter;
      justify-content: flex-start;
      gap: 8px;
      padding: 8px 12px;

      p {
        color: $textColor14;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }

      .desktop-logout-button-svg {
        path {
          stroke: $textColor14;
        }
      }
    }
  }
}


.links-box-mobile {
  .burger-btn {
    @include resetBtn;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 8px;

    svg{
      width: 100%;
      height: 100%;
    }
  }

  .notification-box-mobile {
    @include alignCenter;
    gap: 16px;
  }
}

//.MuiPaper-root {
//  top: 80px;
//  z-index: 80 !important;
//  padding: 24px 16px;
//  width: 100%;
//  box-shadow: none;
//}


.mobile-search-wrapper {
  display: block;
  @media (min-width: $tablet) {
    display: none;
  }
}

.tablet-search-wrapper {
  display: none;
  @media (min-width: $tablet) {
    display: block;
    width: 100%;
  }
  @media (min-width: $desktop) {
    display: none;
  }
}

.mobile-search-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
  @media (min-width: $desktop) {
    display: none;
  }


  .mobile-search-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--Text-color-400, #BEBEBE);
    background: transparent;
    width: 100%;
    max-width: 380px;
    height: 34px;
    padding: 0 16px;

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      outline: none;
      border: none;
      color: #717171;
      font-size: 14px;
      font-weight: 400;
    }

    button {
      @include resetBtn;
      height: 24px;
      width: 24px;
      padding: 0;
    }
  }

  .mobile-search-input:first-of-type {
    margin-bottom: 8px;
  }
}

.mobile-search-address-box {
  background: white;
  width: 300px;
  max-height: 300px;
  height: auto;
  overflow: auto;
}

.MuiDrawer-root.header-menu-drawer {
  height: fit-content;
  padding: 0;
  z-index: 1300 !important;

  @media (min-width: $desktop) {
    display: none !important;
  }

  .MuiPaper-root {
    width: 100%;
    height: fit-content;
    padding: 0;

    @media (min-width: $desktop) {
      display: none;
    }

    .MuiModal-backdrop {
      @media (min-width: $desktop) {
        display: none;
      }
    }

    .mobile-auth-buttons-box {
      flex-direction: row;
      gap: 16px;

      &.mobile-auth-buttons-box-no-user {
        @media (max-width: $sm) {
          flex-direction: column;
        }
      }

      .sign-in, .sign-up {
        width: 100%;
        color: $textColor8;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .sign-in {
        border-radius: 8px;
        border: 1px solid $borderColor1;
        background: $white;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      }

      .sign-up {
        color: $white;
      }
    }

    .sign-out {
      border-radius: 8px;
      background: $borderColor5;
      border: none;
    }
  }
}

.links-mobile-box {
  height: 100%;
  margin-top: 60px;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .links-mobile-box-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px;

    @media (max-width: $sm) {
      padding: 24px 0;
    }

    .mobile-link-row {
      @include alignCenter;
      @include resetBtn;
      gap: 12px;
      width: 100%;
      padding: 12px 16px;

      color: $textColor7;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      &:hover {
        background: $borderColor5;
      }

      &.mobile-link-row-active {
        background: $borderColor5;
      }
    }
  }


  .user-info-mobile-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 0;

    .user-info-mobile-box {
      @include flexCenter;
      gap: 12px;

      .user-info-mobile-icon-block {
        @include flexCenter;
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 200px;
        overflow: hidden;
        background: $backgroundColor2;

        svg {
          path {
            stroke: $borderColor4;
            stroke-width: 2.33333;
          }
        }

        img{
          width: 100%;
          height: 100%;
        }
      }

      .user-info-mobile-full-name {
        color: $textColor7;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  .mobile-navigations-wrapper {
    padding: 24px 32px;

    @media (max-width: $sm) {
      padding: 24px 16px;
    }

    .mobile-navigation-bar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      button {
        width: 100%;
        @include alignCenter;
        @include resetBtn;
        gap: 8px;
        color: $textColor2;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        border-radius: 8px;
        padding: 8px 4px;

        &:hover {
          background: $borderColor5;
        }

        &.mobile-navigation-bar-link {
          background: $borderColor3;
          color: $brandPrimary2;

          svg {
            path {
              stroke: $brandPrimary2;
            }
          }
        }
      }
    }
  }
}
