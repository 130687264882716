@import "../../assets/styles/global";

.input-main-wrapper {
  text-align: start;
  width: 100%;


  .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #3D4751 !important;
  }

  .Mui-disabled {
    -webkit-text-fill-color: #3D4751 !important;
  }

  .MuiInputAdornment-root {
    cursor: pointer;
    width: fit-content;
    height: fit-content;

    &.error-svg {
      svg {
        filter: brightness(0) saturate(100%) invert(40%) sepia(79%) saturate(4141%) hue-rotate(341deg) brightness(98%) contrast(93%);
      }
    }

    &.error-svg-password {
      svg {
        filter: brightness(0) saturate(100%) invert(40%) sepia(79%) saturate(4141%) hue-rotate(341deg) brightness(98%) contrast(93%);
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      display: flex;
      align-items: center;
    }

    input {
      padding: 12px 16px;
    }


    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $errorColor;
        box-shadow: none;
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $errorColor;
        }
      }
    }
  }

  &.input-custom-wrapper {
    .MuiInputBase-root {
      border-radius: 8px;
      outline: none;

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $borderColor1;
        }
      }
    }

    input {
      padding: 12px 16px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $borderColor1;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $borderColor1;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05), 0 0 0 4px #F5F5F5;
      }
    }


    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $errorColor;
        box-shadow: none !important;
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $errorColor;
        }
      }
    }
  }

  .MuiInputAdornment-root ~ input {
    padding-left: 0;
  }

  .MuiSvgIcon-root {
    fill: #000;
  }
}

.input-main-label-header {
  color: $blackMain;
  font-weight: 500;
  margin-bottom: 8px;

  .input-main-label-header-required {
    color: #000;
  }

  &.errorLabel {
    color: $errorColor;

    .input-main-label-header-required {
      color: $errorColor;
    }

  }

}

.input-select-wrapper {
  .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 47px;
    border: none;
  }

  .MuiOutlinedInput-root {
    height: 47px;
    border: none !important;
    outline: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $borderColor1;
    outline: none;
  }

  .MuiAutocomplete-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $borderColor1;
      outline: none;
    }


  }

  .MuiAutocomplete-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $borderColor1;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05), 0px 0px 0px 4px #F5F5F5;
      outline: none;
    }
  }


}

.error-message {
  color: $errorColor;
  font-size: 12px;
  font-weight: 400;
  min-height: 18px;
  margin-left: 15px;
  margin-top: 2px
}

.textarea {
  width: 100%;
  resize: none;
  padding: 12px 16px;
  border: 1px solid $mainBlue;
  color: $black;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;

  &.textarea-error {
    border: 1px solid $errorColor;

    &:hover {
      border: 1px solid $mainBlue;
      outline: 1px solid $mainBlue;
    }
  }

  &:hover {
    outline: 1px solid $mainBlue;
  }

  &:focus {
    border: 1px solid $mainBlue;
    outline: 1px solid $mainBlue;
  }

  &::placeholder {
    color: #C4C4C4;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.checkbox-box {
  .MuiButtonBase-root {
    padding: 0;
  }
}


.custom-select-btn-box {
  height: 44px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  padding: 10px 16px;

  button {
    @include resetBtn;
    @include alignCenter;
    gap: 8px;
    padding: 0;

    svg {
      path {
        stroke: $brandPrimary2;
      }
    }

    .remove-white-select {
      path {
        fill: $textColor5;
        stroke: transparent;
      }
    }

    .arrow-down-select-svg {
      path {
        stroke: $textColor5;
      }
    }
  }

  p {
    color: $textColor8;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
  }
}

.custom-select-btn-active-box {
  height: 44px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $borderColor3;
  background: $borderColor3;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  padding: 10px 16px;


  button {
    @include resetBtn;
    @include alignCenter;
    gap: 8px;
    padding: 0;
    color: #FFF;

    svg {
      path {
        stroke: $brandPrimary2;
      }
    }

    .remove-white-select {
      path {
        fill: $brandPrimary2;
        stroke: transparent;
      }
    }
  }

  p {
    color: $brandPrimary2;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
  }
}

.note-custom-select-paper-box {
  width: 100%;
  @media (min-width: $tablet) {
    width: 500px;
  }
  @media (max-width: $tablet) {
    transform: none !important;
  }

  .MuiBox-root {
    margin-top: 4px;
    width: 100%;
    border: 1px solid #F4F4F4;
    padding: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);

    @media (min-width: $tablet) {
      width: 300px;
    }
  }
}

.notifications-box {
  height: 100vh;
  width: 100%;
  background: $white;

  @media (min-width: $tablet) {
    width: 460px;
    height: 600px;
    border-radius: 8px;
    border: 1px solid $borderColor12;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    margin-top: 34px;
  }
}

.custom-select-paper-box {
  .MuiBox-root {
    margin-top: 16px;
    margin-left: -16px;
    width: 300px;
    border: 1px solid #F4F4F4;
    padding: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  }
}

.custom-select-paper-box-custom {
  z-index: 9999;

  .MuiBox-root {
    margin-top: 4px;
  }
}

.address-suggestion-custom-input-wrapper {
  position: absolute;
  width: 100%;
  border-radius: 4px;
  background: white;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;

  .suggestion-item {
    color: black;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 15px;
    cursor: pointer;
  }
}

.custom-switch-box {
  .MuiSwitch-root {
    padding: 0;
    height: 20px;
    width: 41px;
  }

  .MuiSwitch-switchBase {
    padding: 0;
  }

  .MuiButtonBase-root-MuiSwitch-switchBase {
    padding: 0 !important;
    left: 2px;
  }

  .MuiSwitch-thumb {
    height: 16px;
    width: 16px;
    margin-top: 2px;
    margin-left: 2px;
  }

  .MuiSwitch-track {
    border-radius: 12px;
    opacity: 1;
    background: $borderColor10;
  }

  .MuiSwitch-switchBase.Mui-checked {
    color: white;
    opacity: 1;
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: $brandPrimary;
    opacity: 1;
  }
}

.date-range-selector-mobile {
  height: 44px;
  @include flexCenter;
  @include resetBtn;
  gap: 8px;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

  @media (min-width: $tablet) {
    display: none;
  }
}

.date-range-selector-svg {
  path {
    stroke: $brandPrimary2;
  }
}

.date-range-selector {
  height: 48px;
  display: none;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

  @media (min-width: $tablet) {
    display: flex;
    align-items: center;
  }

  p {
    color: $textColor1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    white-space: nowrap;
  }
}

.rdrStartEdge, .rdrEndEdge {
  background: $brandPrimary;
}

.rdrStartEdge ~ .rdrDayNumber span {
  color: $white;
}

.rdrInRange {
  background: $backgroundColor2;
}

.rdrInRange ~ .rdrDayNumber span {
  color: #172B4D !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  border-color: $brandPrimary;
}

.rdrDayNumber span {
  color: #172B4D;

  &:after {
    background: $brandPrimary !important;
  }
}

