
.stepper-wrapper {
  display: flex;
  align-items: center;

  button {
    height: 40px;
    margin-right: 12px;
    padding: 0 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 14.87px 0px rgba(208, 214, 220, 0.60);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border: none;
    text-transform: capitalize;
  }
  button:hover{
    background: #263E3E;
    color: #FFF;
  }
  button:last-of-type {
    margin-right: 0;
  }

  .active-tab {
    background: #263E3E;
    color: #FFF;
  }

  .passive-tab {
    background: #FFF;
    color: #222;
  }
}