@import '../../../assets/styles/global.scss';


.billing-page {
  @include containerWithPadding;

  .card-information-section {
    margin-top: 40px;
    display: flex;
    padding: 12px;
    flex-direction: column;
    border-radius: 8px;
    background: white;

    @media (min-width: $tablet) {
      padding: 24px;
    }
    @media (min-width: $desktop) {
      padding: 40px;
    }
  }

  .payment-method-title {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    @media (min-width: $tablet) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (min-width: $desktop) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .card-box {
    margin: 16px 0 0;

    @media (min-width: $tablet) {
      padding: 0 0 32px;
      margin: 24px 0 32px;
      border-bottom: 1px solid $borderColor6;
    }

    @media (min-width: $desktop) {
      margin: 24px 0 32px;
    }
  }


  .card {
    max-width: 319px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #FFF;
    background: var(--gradient-gray-90060045-deg, linear-gradient(45deg, #101828 0%, #535862 100%));
    box-shadow: 8px 10px 16px 0 rgba(0, 0, 0, 0.05);
    padding: 20px 16px;
  }

  .current-card {
    p {
      color: $white;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    .card-type {
      color: #222;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    .card-info {

      .card-type-svg-block {
        width: 45px;
        height: 32px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 8px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .expiry-date {
      color: $white;
      font-family: Manropes, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    button {
      @include resetBtn;
    }

    .edit-delete {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 54px;
    }

    .edit {
      color: $white;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    .delete {
      color: $textColor14;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }
  }

  .no-card-box {
    @include flexCenter;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid $white;
    background: $backgroundColor6;
    box-shadow: 8px 10px 16px 0 rgba(0, 0, 0, 0.05);
    padding: 59px 0 58px;
    cursor: pointer;

    .no-card-box-svg-block {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      padding: 10px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    p {
      color: $textColor1;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      margin-top: 12px;
    }
  }

  .we-accept-box {
    @include alignCenter;
    gap: 12px;
    margin-top: 24px;

    p {
      color: $textColor1;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }

    .we-accept-svg-block {
      width: 45px;
      height: 32px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .invoices-table-wrapper {
    border-radius: 12px;
    border: 1px solid $borderColor6;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    margin-top: 24px;
    overflow: hidden;
  }

  .invoices-table-header {
    display: flex;
    align-items: center;
    border-radius: 12px 12px 0 0;
    background: $backgroundColor2;

    .table-header-item {
      padding: 16px 20px;
      color: $brandPrimary2;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .invoices-table-body-wrapper {
    max-height: 60vh;
    overflow: auto;
  }

  .invoices-table-body {
    @include alignCenter;
    border-bottom: 1px solid $borderColor6;
    background: $white;

    .invoices-table-body-item {
      @include alignCenter;
      gap: 16px;
      padding: 15px 20px;
      color: #494949;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .invoices-table-body-item-action-button {
      @include resetBtn;
      @include flexCenter;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      padding: 8px;
      background: $backgroundColor4;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: $textColor8;
        }
      }
    }

    button {
      @include resetBtn
    }
  }

  .invoices-mobile-box {
    margin-top: 32px;
  }

  .invoice-cards-wrapper {
    max-height: 60vh;
    overflow: auto;
    margin-top: 16px;
  }

  .invoice-mobile-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    background: $white;
    padding: 12px;
    margin-bottom: 12px;
  }

  .invoice-info-box {
    @include alignCenter;

    .invoice-box-title {
      color: $textColor1;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-right: 4px;
    }

    .invoice-box-value {
      color: $textColor2;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }


    &.invoice-info-box-padding-border {
      padding-bottom: 8px;
      border-bottom: 1px solid $borderColor6;
    }
  }

  .invoice-mobile-actions {
    @include flexEnd;
    gap: 16px;
    margin-top: 8px;

    button {
      @include resetBtn;
      @include flexCenter;
      width: 40px;
      height: 40px;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: $textColor8;
        }
      }
    }
  }
}

.pdf-reader{
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(50, 54, 57);
  top: 0;
  right: 0;
  z-index: 9999;

  & iframe {
    width: 100%;
    height: 100%;
  }

  & .close-pdf{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 40px;
    border: none;
  }
}