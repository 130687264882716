@import "../../../assets/styles/global";

.organizations-page {

  .organizations-box {
    width: 100%;
    max-width: 1444px;
    margin: 0 auto;
    padding: 32px 16px 24px;

    @media (min-width: $tablet) {
      padding: 40px;
    }
    @media (min-width: $desktop) {
      padding: 40px 80px;
    }
  }

  .organizations-list-box {
    width: 100%;
    max-width: 1444px;
    margin: 0 auto;
    padding: 0 16px 40px;

    @media (min-width: $tablet) {
      padding: 0 40px 40px;
    }
    @media (min-width: $desktop) {
      padding: 0 80px 40px;
    }
  }

  .organization-filters-box {
    @include spaceBetween;
    margin: 40px 0 0;
    gap: 8px;
    overflow-x: auto;
    @media (min-width: $tablet) {
      gap: 16px;
    }

    .types-and-category {
      @include flexCenter;
      gap: 8px;
      @media (min-width: $tablet) {
        gap: 16px;
      }
    }
  }

  .button-main {
    width: 44px;
    height: 44px;
    padding: 12px;

    @media (min-width: $tablet) {
      width: 155px;
      height: 44px;
      padding: 10px 16px;
    }

    @media (min-width: $desktop) {
      width: 163px;
      height: 48px;
      padding: 12px 20px;
    }
  }

  .organization-list-section {
    height: 70vh;
    overflow: auto;
  }


  .organization-map-box {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .organization-map-block {
      width: 40%;
      display: none;

      @media (min-width: $desktop) {
        display: block;
        padding-left: 80px;
      }
    }

    .org-map-view-list {
      width: 100%;
      height: 1000px;
      overflow: auto;

    }
  }

  .organization-map {
    width: 100%;

    @media (min-width: $desktop) {
      width: 60%;
    }
  }

  .org-map-view-section {
    height: 70vh;

    @media (min-width: $desktop) {
      height: 1000px;
    }
  }
}

.org-map-card {
  width: 343px;
  padding: 16px;
  overflow: hidden;
}

.org-map-image-and-title {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.org-map-phone-and-email {
  padding-top: 16px;
  margin-top: 12px;
  border-top: 1px solid $borderColor6;
}

.org-map-image-box {
  @include flexCenter;
  border-radius: 8px;
  background: $backgroundColor9;
  width: 72px;
  min-width: 72px;
  height: 72px;

  svg {
    width: 32px;
    height: 32px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
}

.org-map-title {
  width: 100%;

  p {
    color: $textColor1;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;
  }
}

.org-map-info-box {
  display: flex;
  align-items: center;
  gap: 10px;

  a, p {
    color: $textColor1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-decoration: none;
  }
}

.org-map-card-mobile-button {
  pointer-events: auto;
  width: 100%;
  height: 44px;
  @include resetBtn;
  @include flexCenter;

  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

  color: $textColor8;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  margin-top: 12px;
  transition: all 0.2s ease;

  &:hover {
    color: $textColor22;
    border: 1px solid $borderColor1;
    background: $backgroundColor4;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    transition: all 0.2s ease;
  }
}

.gm-style .gm-style-iw-c {
  padding: 0;
  max-width: 328px;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}


.create-org-box {
  width: 1000px;

  @media (max-width: $desktop) {
    width: 700px;
  }

  @media (max-width: $tablet) {
    width: 100%;
  }

  .create-org-title {
    color: #2A374E;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }
}

.rich-container {
  margin-top: 16px;

  .quill {
    border: 1px solid #bebebe;
    border-radius: 8px;

    .ql-toolbar {
      border-style: solid;
      border-color: #bebebe;
      border-radius: 8px 8px 0px 0px;
      background: #f5f5f5;
    }

    .ql-toolbar.ql-snow {
      border: none;
    }

    .ql-container.ql-snow {
      height: 242px;
      border: none;
    }
  }
}

.create-org-basic-info {
  margin: 24px 0;
}

.org-inputs-box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: $tablet) {
    flex-direction: row;
    gap: 16px;
  }
}

.create-org-form-box {
  margin-top: 24px;
  border-top: 1px solid #F4F4F4;
}

.create-org-basic-title {
  color: #2A374E;
  font-size: 18px;
  font-weight: 600;
}

.create-org-basic-desc {
  color: #2A374E;
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
}

.create-org-btns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;

  button {
    width: 225px;
  }
}


.rct-text {
  margin-bottom: 10px;
  align-items: flex-start !important;
}

.rct-icon-rct-icon-check {
  font-size: 24px;
  width: 24px !important;
  color: #BB3240;
}

.rct-icon-rct-icon-uncheck {
  font-size: 24px;
  width: 24px !important;
  color: #D5D7DA;
}

.react-checkbox-tree label:hover {
  background: transparent;
}

.react-checkbox-tree label {
  display: flex;
  justify-content: flex-start;
}

.rct-collapse {
  width: 24px;
  padding: 0;
  margin-right: 5px;
}

.rct-icon-rct-icon-expand-close {
  font-size: 24px;
  width: 24px !important;
}

.rct-icon-rct-icon-expand-open {
  font-size: 24px;
  width: 24px !important;
}

.react-checkbox-tree button {
  height: 24px;
}

.rct-icon-rct-icon-half-check {
  font-size: 24px;
  width: 24px !important;
  color: gray;

}

.rct-title:hover {
  background: transparent;
}

.rct-title {
  padding: 3px 5px;
}

.rct-icon-rct-icon-leaf-close {
  display: none;
}

.rct-node-icon {
  display: none;
}


.rct-icon-rct-icon-collapse-all {
}

.rct-icon-rct-icon-parent-close {
  display: none;
}

.rct-icon-rct-icon-parent-open {
  display: none;
}


.rct-icon-rct-icon-expand-all {
  background: #868686;
}

.bottom-anchor-box {
  @include flexCenter;
  position: fixed;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  background: #FFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10);
  width: 100%;
  height: 100px;
  @media (min-width: $desktop) {
    display: none;
  }

}

.bottom-anchor-list {
  height: 70vh;


}

.MuiDrawer-paper {
  border-radius: 30px 30px 0 0 !important;
  background: $white !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.10) !important;
}

.discovered-swipe-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  div {
    width: 134px;
    height: 5px;
    border-radius: 40px;
    background: black;
  }

  p {
    margin: 16px 0 24px;
    color: #222;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
}

.discover-organizations-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding:0 16px 95px;

  @media (min-width: $tablet) {
    padding:0 40px 95px;
  }

  @media (min-width: $desktop) {
    gap: 16px;
  }
}

.discover-organizations-pagination {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background: white;
}