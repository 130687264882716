@import "../../../assets/styles/global";


.search-page {

  .search-page-title-filters {
    @include containerWithPadding;
    @include spaceBetween;

    padding-top: 16px;
    padding-bottom: 16px;

    @media (min-width: $tablet) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media (min-width: $desktop) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }

  .search-page-screen {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .searched-cards-pagination {
    display: none;

    @media (min-width: $desktop) {
      width: 40%;
      display: block;
    }

    .searched-cards {
      width: 100%;
      padding: 0 40px;
      height: 1000px;
      overflow: auto;

      @media (min-width: $desktop) {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  .searched-drawer {
    display: block;
    @media (min-width: $desktop) {
      display: none;
    }
  }

  .searched-map {
    width: 100%;
    @media (min-width: $desktop) {
      width: 60%;
    }
  }

  .search-map-card-button {
    width: 40px;
    height: 32px;
    @include resetBtn;
    @include flexCenter;
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 2px 10px;
    border-radius: 8px;
    background: $backgroundColor2;

    p {
      display: none;
    }

    .searched-item-type-svg-arrow {
      display: none;
    }
  }

  .search-card-information-box {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    @media (min-width: $tablet) {
      gap: 16px;
    }
  }

  .search-card-information {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .searched-item-box {
    padding: 16px;
    border-radius: 8px;
    background: $white;

    @media (min-width: $tablet) {
      border-radius: 16px;
      border: 1px solid $borderColor6;
    }
  }

  .searched-item-title {
    @include spaceBetween;
    gap: 12px;
    color: $textColor1;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;

    @media (min-width: $tablet) {
      font-size: 18px;
    }

    .searched-item-type {
      @include resetBtn;
      @include flexCenter;
      position: relative;
      width: 40px;
      height: 42px;
      border-radius: 8px;
      background: $backgroundColor2;
      cursor: pointer;
      text-decoration: none;
      padding: 4px 10px;

      @media (min-width: $tablet) {
        width: fit-content;
        height: fit-content;
        position: static;
        gap: 8px;
        border-radius: 16px;
        //padding: 2px 10px;
      }


      &:hover {
        .searched-item-type-svg-arrow {
          transform: rotate(45deg);
          transition: all .2s;
        }
      }

      .searched-item-type-text {
        display: none;

        @media (min-width: $tablet) {
          display: block;
          color: $brandPrimary2;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .searched-item-type-svg-arrow {
      display: none;
      transition: all .2s;

      @media (min-width: $tablet) {
        display: block;
      }
    }
  }


  .search-card-image {
    @include flexCenter;
    width: 72px;
    min-width: 72px;
    height: 72px;
    border-radius: 8px;
    background: $backgroundColor9;
    overflow: hidden;

    @media (min-width: $tablet) {
      width: 116px;
      min-width: 116px;
      height: 116px;
    }

    svg {
      width: 28px;
      height: 28px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .view-details-btn {
    @include flexCenter;
    width: 100%;
    height: 48px;
    margin-top: 16px;
    padding: 8px 16px;
    border-radius: 4px;
    background: #F2F5F8;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    border: none;
    text-decoration: none;
  }

  .searched-info-box-desktop {
    display: none;
    border-bottom: 1px solid $borderColor6;
    padding-bottom: 16px;

    @media (min-width: $tablet) {
      display: flex;
      flex-direction: column;
      border: none;
      padding-bottom: 0;
      gap: 8px 12px;
    }
  }

  .searched-info-box-mobile {
    display: block;

    @media (min-width: $tablet) {
      display: none;
    }
  }

  .searched-detail-box-mobile {
    display: flex;
    flex-direction: column;
    padding: 12px 0 0 0;
    gap: 8px;
    @media (min-width: $tablet) {
      display: none;
    }
  }

  .searched-info-box {
    @include alignCenter;
    width: 100%;
    gap: 10px;

    @media (min-width: $tablet) {
      gap: 4px;;
    }

    a {
      display: inline-block;
      width: 100%;
      color: $textColor1;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-decoration: none;
    }

    .svg {
      display: flex;
      width: 20px;

      svg {
        path {
          stroke: $textColor4;
        }
      }
    }
  }
}

.gm-style .gm-style-iw-c {
  max-width: 343px !important;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  padding: 0;

  .gm-style-iw-chr {
    display: none;
  }
}