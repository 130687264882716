@import '../../assets/styles/global.scss';

//Tags Cards

.tag-box-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  @media (min-width: $tablet) {
    gap: 16px;
  }

  button {
    @include alignCenter;
    padding: 13px 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 8px;
    height: 44px;

    @media (min-width: $desktop) {
      height: 48px;
    }

    .add-svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }

    .close-svg {
      width: 10px;
      height: 10px;
      margin-left: 8px;

      path {
        fill: $white;
      }
    }
  }

  .active-tag {
    border: 1px solid $brandPrimary2;
    background: $brandPrimary2;
    color: $white;
  }

  .inactive-tag {
    border: 1px solid $borderColor1;
    background: $borderColor7;
    color: $textColor11;
  }

  .show-all-interests {
    @include resetBtn;
    @include flexCenter;
    height: fit-content;
    gap: 8px;
    color: $brandPrimary2;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    transition: all .2s;

    svg {
      transition: all .2s;
    }

    &:hover {
      svg {
        transform: rotate(45deg);
        transition: all .2s;
      }
    }
  }
}