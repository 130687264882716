// Project Global Style.

$brandPrimary: #BB3240;
$brandPrimary2: #85242D;

$white: #ffffff;
$blackMain: #1B1F22;
$black: #000;
$lightBlack: #2A374E;
$footerBlack: #1E2833;
$modalTitle: #152E47;
$inputPlaceholder: #717171;


$textColor1: #181D27;
$textColor2: #535862;
$textColor3: #494949;
$textColor4: #414651;
$textColor5: #475467;
$textColor6: #667085;
$textColor7: #101828;
$textColor8: #344054;
$textColor9: #717680;
$textColor10: #4F151B;
$textColor11: #2F2924;
$textColor12: #222;
$textColor13: #475367;
$textColor14: #D92D20;
$textColor15: #B42318;
$textColor16: #C01048;
$textColor17: #767676;
$textColor18: #D8D8D8;
$textColor19: #252F40;
$textColor20: #363F72;
$textColor21: #C11574;
$textColor22: #1D2939;
$descriptionColor: #3D4751;
$tableRowColor: #3D4C67;
$profileTile: #4B5C68;
$infoText: #A3B2BD;
$grayDark: #ACACAC;
$cardBack: #FFF4E5;
$titleLogo: #080E1C;
$termsTitle: #2B273C;
$whiteMain: #FFF;
$borderColor: #ECF1FA;
$borderColor1: #D5D7DA;
$borderColor2: #A4A7AE;
$borderColor3: #F8EBEC;
$borderColor4: #AA2E3A;
$borderColor5: #F9FAFB;
$borderColor6: #E9EAEB;
$borderColor7: #FAFBFC;
$borderColor8: #D4D4D4;
$borderColor9: #3F3F3F;
$borderColor10: #F2F4F7;
$borderColor11: #EAECF0;
$borderColor12: #E4E7EC;
$borderColor13: #E1E1E1;
$backgroundColor1: #A4A7AE;
$backgroundColor2: #FCF5F5;
$backgroundColor3: #FDFDFD;
$backgroundColor4: #FAFAFA;
$backgroundColor5: #EFEFEF;
$backgroundColor6: #F5F5F5;
$backgroundColor7: #F7F8FA;
$backgroundColor8: #FFF1F3;
$backgroundColor9: #F2F5F8;
$backgroundColor10: #E0A1A7;
$backgroundColor11: #000;
$backgroundColor12: #FDF2FA;
$backgroundColor13: #EABFC4;
$borderColorTemp: #E9E9EB;
$backColorTemp: #FCFDFE;
$whiteBack: #F5F7F9;
$mainBlue: #347AF0;
$mainBlueDisabled: rgba(40, 108, 223, 0.50);
$hoverBtn: #286CDF;
$mainRed: #CD303B;
$errorColor: #F04438;
$deleteButton: #FE385C;
$borderBottom: rgba(156, 156, 156, 0.30);


// Size Option.
$xs: 320px;
$sm: 375px;
$tablet: 767px;
$lg: 1024px;
$desktop: 1279px;
$xl: 1440px;

@mixin containerNoPadding {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

@mixin containerWithPadding {
  width: 100%;
  max-width: 1444px;
  margin: 0 auto;
  padding: 32px 16px 40px;

  @media (min-width: 768px) {
    padding: 40px 40px 64px;
  }
  @media (min-width: 1279px) {
    padding: 40px 80px;
  }
}


@mixin resetBtn {
  background: transparent;
  border: none;
  padding: 0;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@mixin alignCenter {
  display: flex;
  align-items: center;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexStart {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@mixin whiteBox {
  border-radius: 8px;
  background: $white;
  padding: 16px;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

  @media (min-width: $tablet) {
    padding: 24px;
  }
}

.MuiTooltip-popper {
  z-index: 99999999 !important;
}

.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media (max-width: $tablet) {
    padding: 0 32px;
  }

  @media (max-width: $desktop) {
    padding: 0 16px;
  }
}

#popover-my-profile {

  @media (max-width: $desktop) {
    display: none;
  }

  .MuiPaper-root {
    width: 260px;
    height: auto;
    top: 88px !important;
    right: 0 !important;
    left: auto !important;
    padding: 0;
    border-radius: 12px;
    border: 1px solid #E4E7EC;
    background: $white;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
}


.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: $tablet) {
    padding: 0 40px;
  }
  @media (min-width: $desktop) {
    padding: 0 64px;
  }

  //@media (max-width: $xl) {
  //  padding: 0 32px;
  //}
  //
  //@media (max-width: $dp) {
  //  padding: 0 32px;
  //}
  //
  //@media (max-width: $md) {
  //  padding: 0 16px;
  //}

}

.desktop-box {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet-box {
  display: none;
  @media (min-width: 768px) and (max-width: 1119px) {
    display: block;
  }
  @media (max-width: $tablet) {
    display: none;
  }
}

.mobile-box {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.ReactSwipeableBottomSheet--open {
  border-radius: 8px 8px 0 0 !important;
}

.react-swipeable-view-container {
  box-shadow: none !important;
}

// Default Style.

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.full-width {
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}

//::-webkit-scrollbar {
//  width: 12px;
//}
//
//::-webkit-scrollbar-track {
//  background: #9FB1BD;
//  border-radius: 10px;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: #9FB1BD;
//  border-radius: 10px;
//  border: 2px solid #f1f1f1;
//}
//
//::-webkit-scrollbar-thumb:hover {
//  background-color: #555;
//}
//
//* {
//  scrollbar-width: thin;
//  scrollbar-color: #9FB1BD #F7F8FA;
//}
//
//
//::-webkit-scrollbar {
//  width: 5px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  background-color: #F2F5F7;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background-color: #9FB1BD;
//  border-radius: 10px;
//}
//

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.router-wrapper {
  padding: 80px 0 0 0;
  background: $backgroundColor7;

  @media (min-width: 1279px) {
    padding: 80px 0 0 0;
  }
}

.desktop-box {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.slick-prev:before {
  content: url(../images/svg/arrowLeft.svg)
}

.slick-next:before {
  content: url(../images/svg/arrowRight.svg);
}

.app-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.page-global-title {
  color: $textColor1;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: $desktop) {
    font-size: 32px;
  }
}


.add-button-global-content {
  @include flexCenter;
  gap: 8px;

  p {
    @media (max-width: $tablet) {
      display: none;
    }
  }
}

.page-global-title-small {
  color: #222;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 1279px) {
    font-size: 32px;
    line-height: 60px;
  }
}


.mobile-view {
  display: block;
  @media (min-width: $tablet) {
    display: none;
  }
}

.mobile-and-tablet-view {
  display: block;

  @media (min-width: $desktop) {
    display: none;
  }
}

.tablet-and-desktop-view {
  display: none;
  @media (min-width: $tablet) {
    display: block;
  }
}

.desktop-view {
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.view-all-btn {
  width: fit-content;

  a {
    @include alignCenter;
    gap: 8px;
    color: $brandPrimary2;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;

    svg {
      transition: all .2s;
    }
  }

  &:hover {
    a {
      svg {
        transform: rotate(45deg);
        transition: all .2s;
      }
    }
  }
}

.rdrCalendarWrapper {
  background: transparent;
}

.rdrDateDisplayWrapper {
  background: transparent;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: $brandPrimary2 !important;
}

.rdrDateDisplayItemActive  {
  border-color: $brandPrimary2 !important;
}

.rdrDefinedRangesWrapper {
  background: transparent;
  @media (max-width: $tablet) {
    display: none;
  }
}

.rdrStaticRangeSelected {
  border-radius: 8px 0 0 0;
}

.rdrDateRangePickerWrapper {
  box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  border-radius: 8px;
  background: white;
}

.react-multi-carousel-track {
  background: transparent;
}

.react-multiple-carousel__arrow--right {
  display: none;
  right: 0px;
  @media (min-width: $desktop) {
    display: block;
  }
}

.react-multiple-carousel__arrow--left {
  display: none;
  left: 0;

  @media (min-width: $desktop) {
    display: block;
  }
}

.MuiModal-root {
  z-index: 9999 !important;
}

.bottom-border-16 {
  @media (min-width: $tablet) {
    border-bottom: 1px solid $borderColor6;
  }
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-16 {
  @media (min-width: $tablet) {
    padding: 16px 0;
    border-bottom: 1px solid $borderColor6;
  }
}

.padding-top-16 {
  @media (min-width: $tablet) {
    padding-top: 16px;
  }
}

.reset-btn{
  @include resetBtn
}

.gm-style-iw-d{
  overflow: hidden!important;
}