@import "../../assets/styles/global";

.my-profile-wrapper {
  padding: 24px;
  background: $whiteMain;
  border-radius: 8px;
  max-width: 395px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  height: 550px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  //@media (max-width: $dp) {
  //  right: 32px;
  //}
  //
  //@media (max-width: $md) {
  //  right: 16px;
  //}
  //@media (max-width: $sm) {
  //  left: 16px;
  //}

  .my-profile-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .my-profile-sign-out-btn {
    height: 42px;
    padding: 8px 24px;
  }

  .personal-info-wrapper, .change-password-wrapper {
    border-radius: 8px;
    background: $whiteMain;
    border: 1px solid $borderColor;
    padding: 16px 16px 0;
  }

  .edit-button {
    height: 22px;
  }

  .personal-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .info-title {
    color: $profileTile;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .cancel-save-block {
    display: flex;
    gap: 16px;
  }

  .save-person {
    width: 27px;
    height: 20px;
    font-size: 14px;
  }

  .cancel-button {
    height: 20px;
    font-size: 14px;
  }

  .personal-info-content {

    .personal-info-name-svg {
      display: flex;
      align-items: center;
      gap: 8px;

      .personal-info-name {
        color: #4B5C68;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
      }
    }

    .error-profile {
      path {
        stroke: transparent !important;
        fill: $errorColor;
      }
    }
  }

  .change-password-info-text {
    color: $infoText;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

