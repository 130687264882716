@import "../../assets/styles/global";


.modal-wrapper {
  position: relative;
  background: $whiteMain;
  border-radius: 16px;
  padding: 24px;
  height: auto;
  max-height: 85vh;
  overflow: auto;

  @media (min-width: $tablet) {
    padding: 24px;
  }


  .modal-header-style {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 16px;
    top: 16px;

    .close-modal-btn {
      @include flexCenter;
      border-radius: 32px;
      background: #F5F5F5;
      width: 28px;
      height: 28px;
      border: none;
    }
  }

  .modal-body-style {
    height: 100%;
  }
}


.delete-modal-wrapper {
  max-width: 463px;
  display: flex;
  flex-direction: column;
  background: $whiteMain;
  border-radius: 8px;


  .delete-modal-close-button {
    align-self: end;
    cursor: pointer;
  }

  .delete-modal-content {
    font-family: Lato, sans-serif;

    .delete-modal-content-title {
      color: $modalTitle;
      font-size: 24px;
      font-weight: 600;
    }

    .delete-modal-content-text {
      color: $descriptionColor;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 12px 0 24px;
    }

    .delete-modal-content-buttons {
      display: flex;
      gap: 16px;
    }
  }
}