@import "../../../assets/styles/global";

.my-profile-page {
  @include containerWithPadding;

  .my-profile-image-section {
    width: 100%;
    @include flexCenter;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    border-bottom: 1px solid $borderColor6;
    background: $white;
    margin-bottom: 12px;

    @media (min-width: $desktop) {
      padding: 20px;
      margin-bottom: 16px;
    }
  }


  .my-profile-image {
    width: 64px;
    height: 64px;
    @include flexCenter;
    border-radius: 200px;
    border: 4px solid $white;
    background: transparent;
    overflow: hidden;
    box-shadow: 0 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03);

    @media (min-width: $desktop) {
      width: 96px;
      height: 96px;
    }

    input {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.my-profile-no-image {
      padding: 16px;
      background: $backgroundColor2;

      @media (min-width: $desktop) {
        padding: 24px;
      }
    }

    &.my-profile-image-hover {
      background: $backgroundColor7;

      .hovered-img-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0;

        @media (min-width: $desktop) {
          gap: 4px;
        }

        .hovered-img-block-svg {
          width: 28px;
          height: 28px;
          padding: 5px;
          background: $white;
          border-radius: 50%;

          @media (min-width: $desktop) {
            width: 40px;
            height: 40px;
            padding: 6px;
          }

          svg {
            width: 100%;
            height: 100%;
          }
        }

        p {
          color: $textColor2;
          font-family: Inter, sans-serif;
          font-size: 10px;
          font-weight: 400;
          line-height: 17px;

          @media (min-width: $desktop) {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
          }
        }
      }
    }
  }

  .user-name {
    color: $textColor1;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 12px 0 4px;

    @media (min-width: $tablet) {
      font-size: 18px;
      line-height: 27px;
    }
    @media (min-width: $desktop) {
      font-size: 24px;
      line-height: 32px;
      margin: 20px 0 4px;
    }
  }

  .user-email {
    color: $textColor2;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;

    @media (min-width: $desktop) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .my-profile-information-section {
    width: 100%;
    @include flexStart;

    @media (min-width: $tablet) {
      border-radius: 8px;
      background: $backgroundColor3;
      box-shadow: 0 1px 3px 0px rgba(10, 13, 18, 0.10), 0 1px 2px 0 rgba(10, 13, 18, 0.06);
    }


    .my-profile-information-tab {
      display: none;

      @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 204px;
        min-width: 204px;
        padding: 24px 12px;
      }

      @media (min-width: $desktop) {
        width: 342px;
        min-width: 342px;
        padding: 40px 20px;
      }

      button {

        @media (min-width: $tablet) {
          width: 100%;
          @include resetBtn;
          @include alignCenter;
          gap: 8px;
          height: 44px;
          padding: 10px 18px;
          border-radius: 8px;

          color: $textColor5;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }

        @media (min-width: $desktop) {
          font-size: 16px;
          padding: 8px 12px;
        }
      }

      button:hover {
        background: $borderColor3;
        color: $brandPrimary2;

        svg {
          path {
            stroke: $brandPrimary2;
          }
        }
      }

      .tab-active-btn {
        background: $borderColor3;
        color: $brandPrimary2;

        svg {
          path {
            stroke: $brandPrimary2;
          }
        }
      }

      .tab-inactive-btn {
        background: transparent;
        font-weight: 400;
      }
    }

    .my-profile-information-action {
      width: 100%;
      display: none;

      @media (min-width: $tablet) {
        display: block;
        padding: 24px;
        border-left: 1px solid $borderColor6;
      }
      @media (min-width: $desktop) {
        padding: 40px 32px;
      }
    }

    .my-profile-information-action-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: $tablet) {
        display: none;
      }
    }

    .user-info-input-box {
      @include alignCenter;
      gap: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $whiteBack;

      @media (min-width: $tablet) {
      }
      @media (min-width: $desktop) {
      }

      .input-main-wrapper {
        width: 40%;
        @media (min-width: $tablet) {
          width: 55%;
        }
        @media (min-width: $desktop) {
          width: 40%;
        }
      }

      .input-main-wrapper.input-custom-wrapper input {
        color: #3D4751;
        font-size: 14px;

        @media (min-width: $desktop) {
          font-size: 16px;
        }
      }
    }

    .user-password-input-box {
      @include alignCenter;
      gap: 40px;
      border-bottom: 1px solid $whiteBack;
      padding: 0 0 12px 0;
      margin-bottom: 16px;

      @media (min-width: $desktop) {
        padding: 0 0 16px 0;
      }

      .input-main-wrapper {
        width: 50%;
        @media (min-width: $tablet) {
          width: 75%;
        }
      }

      .input-main-wrapper.input-custom-wrapper .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      .input-main-wrapper.input-custom-wrapper input {
        padding: 0;
        color: #3D4751;
        font-size: 14px;

        @media (min-width: $desktop) {
          font-size: 16px;
        }
      }


    }

    .user-info-input {
      border: none;
      background: transparent;
      outline: none;
      color: #3D4751;
      font-size: 14px;
      font-weight: 400;
      width: 50%;

      @media (min-width: $tablet) {
        width: 55%;
      }
      @media (min-width: $desktop) {
        width: 40%;
        font-size: 16px;
      }
    }

    .input-info {
      @include alignCenter;
      gap: 8px;

      @media (min-width: $desktop) {
        gap: 12px;
      }

      p {
        color: $textColor1;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        white-space: nowrap;

        @media (min-width: $tablet) {
          font-size: 14px
        }
        @media (min-width: $desktop) {
          font-size: 16px;
        }
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: $textColor5;
          stroke-width: 2;
        }
      }
    }

    .notification-title-svg {
      width: 235px;
      @media (min-width: $tablet) {
        width: 160px;
      }
      @media (min-width: $desktop) {
        width: 180px;
      }
    }

    .user-info-text {
      color: $textColor2;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}

.user-info-inputs-block {
  //border-radius: 12px;
  overflow: hidden;
  background: $white;

  @media (min-width: $tablet) {
    background: transparent;
  }

  @media (min-width: $desktop) {

  }

  .user-info-inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 12px;
    transition: all .2s;

    @media (min-width: $tablet) {
      padding: 24px 0;
      gap: 16px;
    }

    &.user-info-inputs-no-gap {
      gap: 0;
    }
  }
}

.delete-my-account-accordion {
  height: 0;
  padding: 0 !important;
  overflow: hidden;

  @media (min-width: $tablet) {
    padding: 24px 0 !important;
    height: auto;
    overflow: auto;
  }
}


.edit-info-actions-wrapper {
  padding: 10px 12px;
  border-bottom: 1px solid $borderColor6;
  background: $white;

  @media (min-width: $tablet) {
    background: transparent;
    padding: 0 0 20px;
  }

  @media (min-width: $desktop) {
    background: transparent;
    padding: 0 0 24px;
  }

  &.red-edit-info-title-and-icon {
    background: $borderColor3;
  }

  .edit-info-title-and-icon {
    @include alignCenter;
    gap: 8px;
    @media (min-width: $tablet) {
      align-items: start;
      flex-direction: column;
      gap: 4px;
    }
  }

  .edit-info-icon {
    display: block;

    path {
      stroke: $brandPrimary2;
    }

    @media (min-width: $tablet) {
      display: none;
    }
  }

  .edit-info-box {
    @include spaceBetween;
    gap: 16px;

    @media (min-width: $desktop) {
    }
  }

  .edit-info-title {
    color: $brandPrimary2;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

    @media (min-width: $tablet) {
      color: $textColor1;
      font-size: 16px;
    }
    @media (min-width: $desktop) {
      font-size: 20px;
    }
  }

  .edit-info-actions-accordion-header-svg {
    transform: rotate(180deg);

    &.edit-info-actions-accordion-header-svg-open {
      transform: rotate(0deg);

      path {
        stroke: $brandPrimary2;

      }
    }
  }

  .edit-info-btn {
    @include resetBtn;
    @include flexCenter;
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid $borderColor1;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    transition: all .2s;

    @media (min-width: $tablet) {
      width: 40px;
      height: 40px;
      padding: 10px;
    }

    &:hover {
      border: 1px solid $borderColor1;
      background: $backgroundColor4;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      transition: all .2s;
    }
  }
}

.edit-info-desc-desktop, .edit-info-desc-mobile {
  color: $textColor2;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.edit-info-desc-desktop {
  display: none;

  @media (min-width: $tablet) {
    display: block;
  }
}


.edit-info-desc-mobile {
  display: block;
  margin-bottom: 16px;

  @media (min-width: $tablet) {
    display: none;
  }
}


.user-info-desc {
  color: #152E47;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: $tablet) {
    display: none;
  }
}

.save-cancel-btns {
  @include alignCenter;
  gap: 16px;
}

.save-info-btn, .cancel-info-btn {
  width: 100%;
  height: 40px;

  @include flexCenter;
  @include resetBtn;
  color: $white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  border-radius: 8px;
  border: 1px solid $brandPrimary;
  background: $brandPrimary;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  transition: all .2s;

  @media (min-width: $tablet) {
    height: 36px;
    width: 67px;
  }
}

.save-info-btn {
  &:hover {
    background: $brandPrimary2;
    transition: all .2s;
  }
}

.cancel-info-btn {
  background: $white;
  color: $textColor8;
  border: 1px solid $borderColor1;

  &:hover {
    background: $backgroundColor4;
    transition: all .2s;
  }
}

.delete-account-btn {
  @include resetBtn;
  height: 40px;
  padding: 10px 16px;

  color: $white;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  border-radius: 8px;
  background: $textColor14;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  transition: all .2s;

  @media (min-width: $tablet) {
    max-width: 159px;
    margin: 0;
  }

  &:hover {
    background: $textColor15;
    transition: all .2s;
  }
}


.delete-account-modal {
  width: 100%;
  max-width: 343px;
  padding: 16px;

  @media (min-width: $tablet) {
    max-width: 480px;
    padding: 24px;;
  }

  .delete-account-modal-svg {
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid $borderColor11;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    margin-bottom: 16px;

    @media (min-width: $tablet) {
      margin-bottom: 20px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .delete-account-modal-title {
    width: 100%;
    color: $textColor1;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .delete-account-modal-desc {
    color: $textColor2;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }

  .delete-account-modal-actions {
    width: 100%;
    @include alignCenter;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;

    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 16px;
      margin-top: 32px;
    }
  }
}