@import '../../../assets/styles/global.scss';


.event-details-page {
  padding: 0 0 80px 0;

  .buy-ticket-btn {
    width: 100%;
    max-width: 311px;
    height: 44px;
    @include resetBtn;
    @include flexCenter;
    gap: 8px;
    border: 1px solid $borderColor1;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    border-radius: 8px;
    padding: 10px 18px;

    @media (min-width: $tablet) {
      max-width: 155px;
    }

    @media (min-width: $desktop) {
      max-width: 159px;
      padding: 12px 20px;
    }

    p {
      color: $textColor8;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
      white-space: nowrap;
    }
  }

  .contact-organizer {
    @include resetBtn;
    display: flex;
    align-items: center;
    color: $brandPrimary2;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;

    p {
      margin-left: 8px;
      text-decoration-line: underline;
    }

    .contact-organizer-text {
      display: none;

      @media (min-width: $tablet) {
        display: block;
      }
    }


    .contact-organizer-text-mobile {
      display: block;

      @media (min-width: $tablet) {
        display: none;
      }
    }

    svg {
      path {
        stroke: $brandPrimary2;
      }
    }

    .contact-organizer-by-svg {
      path {
        stroke: #292D32;
      }
    }
  }

  .event-information-wrapper {
    padding-bottom: 16px;
    background: white;
    @media (min-width: $tablet) {
      padding-bottom: 24px;
    }
    @media (min-width: $desktop) {
      padding-bottom: 32px;
    }

    .event-information-container {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      position: relative;
      @media (min-width: $tablet) {
        padding: 0 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 64px;
      }
    }

    .event-information-breadcrumb-wrapper {
      padding: 16px 0;
      margin: 0;
    }

    .single-event-image-layar {
      height: 557px;
      width: 100%;
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0;
      @media (min-width: $tablet) {
        border-radius: 8px;
      }
    }

    .single-event-image {
      background-image: url(../../../assets/images/img/eventImage.png);
      background-size: cover;
      background-repeat: no-repeat;
      height: 557px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      background-position: center;
      @media (min-width: $tablet) {
        border-radius: 8px;
      }
    }

    .event-images {
      @include resetBtn;
      @include flexCenter;
      gap: 4px;
      position: relative;
      padding: 8px 14px;
      border-radius: 8px;
      border: 1px solid $borderColor1;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

      @media (min-width: $tablet) {
        padding: 12px;
      }

      .event-images-text {
        display: none;

        @media (min-width: $tablet) {
          display: block;
        }
      }

      .event-images-text-mobile {
        display: block;

        @media (min-width: $tablet) {
          display: none;
        }
      }

      p {
        color: $white;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        @media (min-width: $tablet) {
          font-size: 16px;
        }
      }

      svg {
        path {
          stroke: $white;
        }

        @media (max-width: $tablet) {
          display: none;
        }
      }
    }

    .event-date-time {
      width: 100%;
      height: 100%;
      @include column;
      justify-content: center;
      align-items: center;
      position: relative;

      .event-date {
        color: $white;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;

        @media (min-width: $tablet) {
          font-size: 24px;
        }
      }

      .only-left {
        border-radius: 4px;
        background: $white;
        padding: 4px 8px;
        margin-top: 12px;

        @media (min-width: $tablet) {
          margin-top: 16px;
        }


        p {
          color: $textColor15;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 21px;
        }
      }

      .time-left {
        display: flex;
        height: 69px;
        padding: 8px;
        margin-top: 24px;

        border-radius: 8px;
        border: 2px solid $borderColor6;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

        @media (min-width: $tablet) {
          height: 94px;
          padding: 16px 24px;
          border: 3px solid $borderColor6;
        }

        .date-box {
          @include flexCenter;
          flex-direction: column;
          color: $white;
          width: 73px;
          text-align: center;
          border-right: 1px solid $white;

          @media (min-width: $tablet) {
            width: 80px;
          }

          p {
            font-size: 24px;
            font-weight: 700;
            line-height: 120%;

            @media (min-width: $tablet) {
              font-size: 32px;
            }
          }

          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }

        .date-box:nth-child(2), .date-box:nth-child(3) {
          width: 80px;

          @media (min-width: $tablet) {
            width: 95px;
          }
        }

        .date-box:last-of-type {
          //padding: 0 0 0 16px;
          border-right: none;
        }
      }
    }

    .single-event-information-box {
      border-bottom: 1px solid $borderColor8;
      padding: 16px;
      margin: 0 0 16px;

      @media (min-width: $tablet) {
        border-radius: 8px 8px 0 0;
        padding: 0 0 16px 0;
        margin: 24px 0 16px;
      }

      @media (min-width: $desktop) {
        margin: 32px 0 20px;
        padding: 0 0 16px;
      }

      .event-name {
        color: $black;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;

        @media (min-width: $tablet) {
          font-size: 24px;
        }
        @media (min-width: $desktop) {
          font-size: 36px;
          font-weight: 700;
        }
      }

      .event-location-time {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        p {
          margin-left: 8px;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          @media (min-width: $tablet) {
            margin-left: 16px;
          }
        }

      }

      .event-info-icon {
        min-width: 24px;
        width: 24px;

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: $borderColor9;
          }
        }
      }

      .event-organizer-box {
        @include spaceBetween;
        gap: 16px;

        @media (min-width: $tablet) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 0;
        }

        .organization-logo {
          @include flexCenter;
          width: 32px;
          height: 32px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.10);
          background: $white;
          border-radius: 40px;

          @media (min-width: $tablet) {
            width: 50px;
            height: 50px;
          }

          img {
            width: 32px;
            height: 32px;
            border-radius: 40px;
            object-fit: cover;
            @media (min-width: $tablet) {
              width: 50px;
              height: 50px;
            }
          }
        }

        .organizer-name {
          color: $textColor12;
          font-size: 16px;
          font-weight: 400;
          margin-left: 16px;

          a {
            color: $textColor12;
            text-decoration: underline;
          }
        }
      }
    }

    .action-buttons-box {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      width: 100%;
      padding: 0 16px;

      @media (min-width: $tablet) {
        padding: 0;
      }

      .action-btn {
        @include resetBtn;
        @include flexCenter;
        height: 44px;
        width: 44px;
        color: $textColor8;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid $borderColor1;
        background: $white;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        padding: 10px 0;
        white-space: nowrap;

        @media (min-width: $tablet) {
          height: 48px;
          width: auto;
          padding: 10px 18px;
        }

        p {
          margin-left: 8px;
          display: none;
          @media (min-width: $tablet) {
            display: block;
          }
        }
      }
    }
  }


  //Event Details Section
  .bold-title-18 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .event-map-and-organizations {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: $desktop) {
      width: 50%;
    }
  }

  .event-details {
    display: flex;
    flex-direction: column;
    gap: 0;
    max-width: 1440px;
    margin: 0 auto;
    padding: 16px;
    @media (min-width: $tablet) {
      padding: 16px 32px;
    }
    @media (min-width: $desktop) {
      gap: 32px;
      padding: 32px 32px 80px;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .detail-title {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    @media (min-width: $tablet) {
      font-size: 20px;
    }
  }

  .details-white-box {
    width: 100%;
    @include column;
    margin-bottom: 10px;
    gap: 16px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    padding: 16px;

    @media (min-width: $tablet) {
      gap: 24px;
      padding: 24px;
    }
  }

  .event-details-section {
    width: 100%;
    @media (min-width: $desktop) {
      width: 50%;
    }

    .tickets-price-button-block {
      @include spaceBetween;
      flex-direction: column;
      align-items: start;
      gap: 16px;


      @media (min-width: $tablet) {
        flex-direction: row;
        align-items: center;

      }
    }

    .ticket-price {
      display: flex;
      align-items: center;
      margin: 16px 0;

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .only-spots-left {
      color: $textColor12;
      font-size: 16px;
      font-weight: 400;
    }

    .detail-item-box {
      @include alignCenter;
      margin-bottom: 12px;

      p {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .event-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }

    .detail-tags-box {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      div {
        padding: 2px 10px;
        border-radius: 16px;
        background: $backgroundColor8;
        border: none;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px
      }
    }


    .event-sponsors-box {
      height: auto;
      max-height: 200px;
      overflow: auto;
      margin-top: 8px;
      @include column;
      gap: 8px;

      .event-sponsor {
        @include alignCenter;
        height: 40px;
        gap: 8px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid $borderColor6;
        background: $backgroundColor4;

        a {
          color: $textColor12;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-decoration: none;
        }
      }
    }
  }


  //Events You Like

  .events-you-like {
    max-width: 1440px;
    margin: 0 auto;

    .event-list-card-wrapper {
      margin: 0 12px 12px;
    }


    .events-you-like-box {
      padding: 0 16px;

      @media (min-width: $tablet) {
        padding: 0 32px;
      }
    }

    .events-you-like-box-carousel-wrapper {
      position: relative;

      @media (min-width: $desktop) {
        padding: 0 50px;
      }

      .slide-arrow-button {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid $borderColor6;
        background: $white;
        padding: 8px;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

        @media (min-width: $desktop) {
          @include flexCenter;
        }

        svg {
          filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(1099%) hue-rotate(181deg) brightness(97%) contrast(92%);
        }

        &.slide-arrow-button_left {
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        &.slide-arrow-button_right {
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        &.disabled-slide-arrow-button {
          cursor: auto;

          svg {
            filter: brightness(0) saturate(100%) invert(76%) sepia(0%) saturate(2176%) hue-rotate(305deg) brightness(89%) contrast(89%);
          }
        }
      }

      .react-multi-carousel-list {
        background: transparent;

        .react-multi-carousel-track {
          display: flex;

          .react-multi-carousel-item {
            padding: 0 8px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }


    .slick-prev {
      left: -50px;
    }

    .slick-prev::before {
      font-size: 40px;
      color: black;
    }

    .slick-next::before {
      font-size: 40px;
      color: black;
    }

    .slick-next {
      right: -50px
    }

    .slick-prev, .slick-next {
      width: 40px;
      height: 40px;
      z-index: 99;
    }

  }

  .event-map-section {
    display: block;
    border-radius: 8px;
    background: white;

    .map-details {
      padding: 16px;

      @media (min-width: $tablet) {
        padding: 24px;
      }
      @media (min-width: $desktop) {
        padding: 24px 32px;
      }
    }

    .community {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .formatted-address {
      color: #65676B;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 6px;
    }
  }

  .organized-by-info {
    @include spaceBetween;
    margin: 16px 0;
    flex-direction: column;

    @media (min-width: $tablet) {
      //padding: 24px;
      flex-direction: row;
    }

  }

  .organized-by-box {
    @include alignCenter;
    flex-direction: column;
    gap: 16px;
    @media (min-width: $tablet) {
      flex-direction: row;
    }
  }

  .organized-by-name-and-info {
    width: 100%;
    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .organized-by-icon {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 40px;
    box-shadow: 0px 0px 6px #0000001a;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      object-fit: contain;
    }
  }

  .organized-by-name {
    color: $textColor12;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 2px;
  }

  .org-information {
    display: flex;
    align-items: center;
    color: $textColor12;
    font-size: 14px;
    font-weight: 400;

    div {
      width: 5px;
      height: 5px;
      border-radius: 40px;
      background: #5B5B5B;
      margin: 0 4px;
    }
  }

  .follow-btn, .following-btn {
    width: 118px;
    height: 48px;
    @include resetBtn;
    @include flexCenter;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $borderColor3;
    background: $borderColor3;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    color: $brandPrimary2;
    transition: all .2s;


    @media (max-width: $tablet) {
      width: 100%;
      margin: 10px 18px;
    }

    &:hover {
      border: 1px solid $backgroundColor13;
      background: $backgroundColor13;
      transition: all .2s;
    }

    p {
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    svg {
      min-width: 20px;
      height: 20px;
      filter: brightness(0) saturate(100%) invert(19%) sepia(43%) saturate(2123%) hue-rotate(323deg) brightness(94%) contrast(97%);
    }
  }

  .follow-btn {
    border: 1px solid $brandPrimary;
    background: $brandPrimary;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    color: $white;

    &:hover {
      border: $brandPrimary2;
      background: $brandPrimary2;
    }

    svg {
      min-width: 20px;
      height: 20px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(181deg) brightness(103%) contrast(103%);
    }
  }

  .org-links-box {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet) {
      gap: 16px;
    }
  }
}


.ticket-category-box {
  padding: 16px;

  @media (min-width: $desktop) {
    padding: 40px;
  }

  .MuiAccordion-root {
    padding: 0;
    box-shadow: none;
  }

  .MuiAccordionDetails-root {
    border: 1px solid #F4F4F4;
  }

  .MuiAccordion-root:before {
    display: none;
    background-color: transparent !important;
  }
}