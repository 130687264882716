@import "../../assets/styles/global";

.footer-section {
  width: 100%;
  height: 100%;
  background-color: $backgroundColor11;

  .footer-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 48px;
    padding: 48px 16px 24px;

    @media (min-width: $tablet) {
      padding: 48px 32px 32px;
    }

    @media (min-width: $desktop) {
      max-width: 1440px;
      margin: 0 auto;
      padding: 64px 80px 48px;
    }

    .footer-logo-links-send-form {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
        gap: 32px;
      }

      .footer-logo-description_links-block {

        .footer-logo_description {
          display: flex;
          flex-direction: column;
          gap: 24px;

          @media (min-width: $desktop) {
            gap: 32px;;
          }

          .footer-logo-block {
            width: 106px;
            height: 28.952px;

            @media (min-width: $tablet) {
              width: 106px;
              height: 28.952px;
            }

            @media (min-width: $desktop) {
              width: 117.16px;
              height: 32px;
            }

            img {
              width: 100%;
              height: auto;
            }
          }

          .footer-description {
            width: 100%;
            color: $white;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .footer-links-wrapper {
          display: flex;
          gap: 32px;
          margin-top: 32px;

          .footer-links-block-mobile {
            width: calc(100% - 32px);
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media (min-width: $tablet) {
              display: none;
            }

            a {
              color: #FFF;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-decoration: none;
            }
          }

          .footer-links-block {
            display: none;

            @media (min-width: $tablet) {
              display: flex;
              gap: 12px;

            }

            a {
              color: #FFF;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-decoration: none;
            }
          }
        }
      }

      .send-form-privacy-text {
        width: 100%;

        @media (min-width: $tablet) {
          max-width: 434px;
        }

        @media (min-width: $desktop) {
          max-width: 434px;
        }

        .send-email-form {
          width: 100%;
          height: 44px;
          @include flexCenter;
          gap: 16px;

          @media (min-width: $tablet) {
            height: 48px;
          }

          .send-email-input-block {
            @include flexCenter;
            gap: 8px;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            border: 1px solid $borderColor1;
            background: $white;
            box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
            padding: 12px 14px 12px 16px;

            .send-email-input-svg {
              svg {
                filter: brightness(0) saturate(100%) invert(49%) sepia(3%) saturate(1145%) hue-rotate(186deg) brightness(93%) contrast(92%);
              }
            }

            input {
              width: 100%;
              height: 100%;
              border: none;
              outline: none;

              color: $textColor1;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;

              &::placeholder {
                color: $textColor9;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
              }
            }
          }

          .send-email-button {
            @include flexCenter;
            height: 100%;
            min-width: 44px;
            border-radius: 8px;
            border: 1px solid $brandPrimary;
            background: $brandPrimary;
            box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
            padding: 12px;
            transition: all .2s;

            @media (min-width: $tablet) {
              min-width: 119px;
              padding: 12px 20px;
            }

            &:hover {
              background: $textColor15;
              transition: all .2s;
            }

            .send-email-svg {
              @media (min-width: $tablet) {
                display: none;
              }
            }

            .send-email-button-text {
              display: none;
              @media (min-width: $tablet) {
                display: block;
                color: $white;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
              }
            }
          }
        }

        .send-email-privacy-text {
          color: $textColor18;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 8px;

          a {
            color: $textColor18;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
          }
        }
      }
    }


    .socials-copy-right-block {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-top: 1px solid $borderColor12;
      padding-top: 32px;

      @media (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
      }

      .footer-socials-block {
        @include flexCenter;
        justify-content: start;
        gap: 24px;

        @media (min-width: $desktop) {
          order: 2;
        }

        .footer-socials-block-item {
          cursor: pointer;
        }
      }

      .footer-copy-right {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @media (min-width: $desktop) {
          order: 1;
        }
      }
    }
  }
}