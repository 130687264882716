// Reset Style.

*,
*:before,
*:after {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
    display: block;
}

html {
    scroll-behavior: smooth;
    & .MuiContainer-maxWidthLg {
        @media (min-width: 1200px) {
            max-width: 1440px;
        }
    }
}

body {
    margin: 0 auto;
    line-height: 1;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;

    overflow-x: hidden;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

input[type='time']::-webkit-calendar-picker-indicator {
    font-size: 22px;
    background-size: contain;
}
input::-webkit-datetime-edit-fields-wrapper {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-left: 0;
}

button {
    outline: none;
    cursor: pointer;
}

// Material Important Stayle.

.css-1m6607n-MuiListItemAvatar-root {
    margin-top: 0;
}

.css-jn01mi {
    display: none !important;
}

.MuiAutocomplete-popper {
    z-index: 99999 !important;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
