@import '../../assets/styles/global.scss';

.event-grid-card-wrapper {
  width: 320px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px -8px 80px 0px rgba(0, 0, 0, 0.07), 0px -2.92px 29.201px 0px rgba(0, 0, 0, 0.05), 0px -1.418px 14.177px 0px rgba(0, 0, 0, 0.04), 0px -0.695px 6.95px 0px rgba(0, 0, 0, 0.03), 0px -0.275px 2.748px 0px rgba(0, 0, 0, 0.02);

  @media (min-width: $tablet) {
    width: 340px;
  }
  @media (min-width: $desktop) {
    width: 416px;
  }

  .event-image-box {
    height: 215px;
    border-radius: 8px 8px 0 0;
    background-size: cover;
    background-color: $backgroundColor5;
    position: relative;

  }

  .event-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 215px;

    img {
      height: 215px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }

  .event-type-box {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    position: absolute;
    right: 0;


    .event-type {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      padding: 5px 10px;
      border-radius: 4px;
      background: #FFF;
      font-size: 10px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .event-information-box {
    padding: 12px;
    height: 180px;

    @media (min-width: $desktop) {
      padding: 16px;
    }

    .event-title {

      p {
        color: $textColor1;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        @media (min-width: $desktop) {
          font-size: 18px;
        }
      }
    }

    .date-time-location-box {
      height: 55px;
      margin: 16px 0 24px;

    }

    .event-info-box {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 8px;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;

        color: $textColor1;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
      }

      .your-time {
        font-weight: 600;
      }

      .icon-box {
        width: 20px;
        height: 20px;

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: $textColor9;
          }
        }
      }
    }

    .event-info-box:last-of-type {
      margin-bottom: 0;
    }
  }

  .actions-btn {
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #F4F7FA;
    border: none;
  }

  .rsvp-btn-box {
    @include resetBtn;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    gap: 8px;

    color: $brandPrimary2;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    margin-left: auto;

    &:hover {
      svg {
        transform: rotate(45deg);
        transition: all .2s;
      }
    }

    svg {
      transition: all .2s;
    }
  }
}


.event-list-card-wrapper {
  width: 100%;
  padding: 12px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: $white;
  border: 1px solid $borderColor6;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  margin-bottom: 16px;
  cursor: pointer;

  @media (min-width: $tablet) {
    gap: 16px;
    padding: 16px;
  }

  @media (min-width: $desktop) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 12px;
    gap: 20px;
    transition: all .2s;
  }

  &:hover {
    .list-card-info-box {
      background: $backgroundColor2;
      transition: all .2s;

      .icon-box {
        svg {
          path {
            stroke: $brandPrimary2;
            transition: all .2s;
          }
        }
      }
    }

    .view-details-btn {
      svg {
        transform: rotate(45deg);
        transition: all .2s;
      }
    }
  }


  .event-list-card-image-title {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    @media (min-width: $desktop) {
      width: 70%;
    }

    .list-event-title {
      color: $textColor1;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      margin: 8px 0;


      @media (min-width: $desktop) {
        font-size: 18px;
      }
    }

    .event-desc {
      color: $textColor2;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media (min-width: $desktop) {
        font-size: 16px;
      }
    }
  }

  .event-list-card-image-box {
    height: 95px;
    width: 95px;
    min-width: 95px;
    border-radius: 8px;
    background: $backgroundColor5;

    .event-list-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 95px;

      img {
        height: 95px;
        width: 95px;
        min-width: 95px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .list-event-type-box {
      position: absolute;
      z-index: 2;
      margin: 4px;
      border-radius: 4px;
      width: fit-content;
      height: auto;
      padding: 4px;
      color: #000;
      font-size: 8px;
      font-weight: 400;
      background: white;
      @media (min-width: $tablet) {
        font-size: 10px;
      }
    }
  }

  .event-list-card-title-desc {
    width: 100%;
  }

  .list-card-info-box {
    width: 100%;
    padding: 12px 16px;
    border-radius: 6px;
    background: $backgroundColor4;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (min-width: $desktop) {
      width: 30%;
      padding: 12px 16px;
    }

  }

  .info-row {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $textColor1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    .icon-box {
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: $textColor2;
        }
      }
    }
  }

  .view-details-btn {
    width: fit-content;
    @include resetBtn;
    @include flexCenter;
    gap: 8px;
    color: $brandPrimary2;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-left: auto;
    text-decoration: none;

    p {
      @media (min-width: $desktop) {
        display: none;
      }
    }

    svg {
      transition: all .2s;
    }
  }
}


.paper-box {
  .MuiBox-root {
    width: 340px;
    border-radius: 8px;
    border: 1px solid #F4F4F4;
    padding: 8px;
  }
}

.cta-paper-box {
  .MuiBox-root {
    width: 200px;
    border-radius: 8px;
    border: 1px solid $borderColor10;
    background: $white;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }
}

.beck-drop {
  height: 100vh;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
}

.action-btns-box {
  @include column;
  width: 100%;

  button {
    border-radius: 8px;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: transparent;

    p {
      margin-left: 8px;
      color: $textColor8;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    svg {
      path {
        stroke: #292D32;
      }
    }
  }

  button:hover {
    border-radius: 8px;
    background: $backgroundColor2;

    p {
      font-weight: 500;
    }

    svg {
      path {
        stroke-width: 1.25;
      }
    }
  }

  .active-rsvp-button {
    border-radius: 8px;
    background: $backgroundColor2;

    p {
      font-weight: 500;
    }

    svg {
      path {
        stroke-width: 1.25;
      }
    }
  }
}


.rsvp-btn-box {
  @include resetBtn;
  @include spaceBetween;
  width: 172px;
  height: 44px;
  color: $textColor8;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  padding: 10px 18px;

  @media (min-width: $tablet) {
    width: 172px;
    height: 48px;
  }

  p {
    margin-left: 8px;
  }
}


.cta-btn-box {
  @include resetBtn;
  @include flexCenter;
  height: 44px;
  width: 44px;
  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

  @media (min-width: $tablet) {
    height: 48px;
    width: 48px;
  }

  p {
    margin-left: 8px;
  }
}

