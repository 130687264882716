@import "../../assets/styles/global";

div, p, span, button {
  font-family: Inter, sans-serif;
}

.login-wrapper {

  background: $whiteMain;
  border-radius: 12px;
  overflow: hidden;

  @media only screen and (max-width: $tablet) {
    width: 463px;
  }

  @media only screen and (max-width: $desktop) {
    max-width: 343px;
    width: 100%;
  }

  .login-img-block {
    width: 250px;
    height: 488px;

    @media only screen and (max-width: $tablet) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .forgot-img-block {
    width: 250px;
    height: 373px;

    @media only screen and (max-width: $tablet) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .check-email-img-block {
    width: 250px;
    height: 249px;

    @media only screen and (max-width: $tablet) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .welcome-to-img-block {
    width: 250px;
    height: 338px;

    @media only screen and (max-width: $tablet) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .forgot-box {
    align-items: flex-start !important;
  }

  .login-content {
    width: 500px;
    padding: 40px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $tablet) {
      width: 100%;
      padding: 32px;
    }

    @media only screen and (max-width: $desktop) {
      padding: 24px;
    }

    .login-title {
      font-size: 24px;
      font-weight: 800;
      line-height: 40px;
    }


    .forgot-desc, .check-email {

      font-weight: 500;
      margin-top: 15px;
    }

    .check-email {
      //color: $mainBlue;
    }


    .login-sign-in-button {
      width: 100%;
      margin: 14px 0 0;
      height: 42px;
    }


    .log_forgot-sign-up-btn {
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 14px;
      line-height: 24px;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      opacity: .9;
    }

    .log_forgot-btn {
      //color: $mainBlue;
      margin-top: 16px;
    }


    //welcome-content
    &.welcome-content {
      padding: 16px;
      display: flex;
      flex-direction: column;

      .close-button {
        align-self: end;
        cursor: pointer;
        transition: all .3s;

        &:hover {
          opacity: .8;
          transition: all .3s;
        }
      }

      .welcome-to-content-box {
        padding: 0;
        margin: auto 0;

        @media (min-width: 768px) {
          padding: 16px;
        }
      }

      .done-button {
        margin-top: 32px;
      }
    }
  }

  &.reset-wrapper {
    margin: 76px auto 0;
    border-radius: 8px;
    background: $whiteMain;
    box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);

  }
}

//CREATE ACCOUNT

.create-acc-wrapper {
  width: 100%;

  .create-acc-header {
    padding: 22px 0;
    background: #FFF;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

    .create-acc-header-logo {
      cursor: pointer;
    }
  }

  .create-acc-content {
    padding: 50px 0;
    display: flex;
    justify-content: end;

    @media only screen and (max-width: $tablet) {
      justify-content: center;
    }

    .create-account-modal {
      padding: 24px 16px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);

      @media (min-width: 768px) {
        padding: 32px 40px;
      }

    }

    .create-account-modal-title {
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 28px;
      font-weight: 800;
      line-height: 40px;
    }

    .create-account-modal-desc {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin: 16px 0 32px;
    }

    .create-ac-form {
      display: flex;
      flex-direction: column;

      .create-acc-button {
        height: 48px;
      }

    }

    .profile-svg {
      path {
        fill: $mainBlue;
      }
    }

    .error-profile {
      path {
        stroke: transparent !important;
        fill: $errorColor;
      }
    }

    .i-have-read {
      display: flex;
      align-items: center;
      margin: 16px 0;


      .i-have-read-checkbox {
        margin-right: 8px;
      }

      .i-have-read-text, .i-have-read-text-link {
        color: $descriptionColor;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 400;

        .i-have-read-text-link {
          margin: 0 2px;
        }
      }
    }

    .already-have-block {
      display: flex;
      align-items: center;
      margin-top: 24px;
      font-family: Lato, sans-serif;

      .already-have {
        color: $descriptionColor;
      }

      .already-have-link {
        color: $mainBlue;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .create-account-unlock-exclusive {
      width: 100%;
      margin-left: 80px;
      max-width: max-content;

      @media only screen and (max-width: $tablet) {
        display: none;
      }

      .unlock-exclusive-title {
        color: $modalTitle;
        font-family: Lato, sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 36px;
        text-transform: capitalize;
        max-width: 366px;
      }

      .unlock-exclusive-cards-wrapper {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .unlock-exclusive-card {
          background: $cardBack;
          border-radius: 8px;
          padding: 13px 24px;
          box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);
          display: flex;
          align-items: center;
          gap: 8px;

          .unlock-exclusive-card-text {
            color: $modalTitle;
            font-family: Lato, sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }
}


// NEW STYLES
.form-box {
  margin-top: 48px;
  width: 100%;

  @media (max-width: $tablet) {
    margin-top: 32px;
  }

  button {
    margin-top: 24px;

    @media (max-width: $tablet) {
      margin-top: 15px;
    }
  }
}

.form-box-forgot {
  margin-top: 24px;
  width: 100%;

  button {
    margin-top: 6px;
  }
}

.invite-success-modal {
  text-align: center;

  button {
    margin-top: 40px;
  }
}


// Login Modal
.login-modal-box {
  width: 430px;
  text-align: center;


  @media only screen and (max-width: $tablet) {
    width: 310px;
  }

  @media only screen and (max-width: $sm) {
    width: 100%;
  }

  .welcome-back {
    color: $textColor2;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }

  .forgot-btn {
    @include resetBtn;
    margin: 16px 24px;
    color: $brandPrimary2;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .error-fullName-icon {
    path {
      fill: $errorColor;
      stroke: transparent !important;
    }
  }
}

.dont-have {
 @include flexCenter;
  flex-wrap: wrap;

  span {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: #2A374E;
    font-weight: 500;
  }

  button {
    @include resetBtn;
    color: $brandPrimary;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 3px;
  }
}

.agree-terms {
  color: $textColor3;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;

  a {
    color: $textColor3;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.login-svg-block {
  @include flexCenter;
  width: 48px;
  height: 48px;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid $borderColor11;
  background: $whiteMain;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  margin: 0 auto 16px;
}


.auth-modal-title {
  color: $textColor1;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px
}

.auth-modal-description {
  color: $textColor2;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

.beck-to-login-btn {
  @include resetBtn;
  @include flexCenter;
  gap: 8px;
  margin: 32px auto 0 !important;

  span {
    color: $textColor2;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.invite-modal-des {
  color: #51566D;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.dont-receive-block {
  @include flexCenter;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 32px;

  .dont-receive-block-text, .click-resent-btn {
    color: $textColor4;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .click-resent-btn {
    @include resetBtn;
    color: $brandPrimary;
    font-weight: 600;
  }
}

// Login Modal

.forgot-modal-desc {
  max-width: 300px;
  color: $textColor2;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px auto 0;
}

.reset-modal-box {
  width: 430px;

  .reset-modal-desc {
    color: #51566D;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 16px;
  }
}


.welcome-to-wrapper {
  width: 350px;
  text-align: center;

  .welcome-modal-desc {
    margin: 8px 0 40px;
    color: #51566D;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}


.invite-modal {
  max-width: 434px;
  width: 100%;
  text-align: center;
}