@import "../../assets/styles/global";

.button-main {
  @include flexCenter;
  border: none;
  width: 100%;
  padding: 12px 24px;
  background: $brandPrimary;
  white-space: nowrap;
  border-radius: 8px;
  transition: all .2s;
  height: 48px;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &:disabled {
    pointer-events: none;
    background: $backgroundColor10;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    cursor: auto;
  }

  &:hover {
    background: $brandPrimary2;
  }
}


.button-start-adornment {
  display: inline-flex;
  margin-right: 8px;
}

.button-end-adornment {
  display: inline-flex;
  margin-left: 8px;
}

.contained {
  background: #000;
  color: $whiteMain;
  transition: all .2s;

  &:hover {
    background: $hoverBtn;
    transition: all .2s;
  }

  &:disabled {
    pointer-events: none;
    background: $mainBlueDisabled;
    border: 1px solid $mainBlueDisabled;
  }
}

.outlined {
  color: $mainBlue;
  background: $whiteMain;
  transition: all .2s;

  &:hover {
    font-weight: 500;
    transition: all .2s;
    color: $hoverBtn;
    outline: 1px solid $hoverBtn;
  }
}

.cancel-button {
  border: none;
  background: $whiteBack;
  color: $descriptionColor;

  &:hover {
    background: #EBF2FD;
  }

  &:disabled {
    background: rgba(235, 242, 253, 0.50);
  }
}

.delete-button {
  background: $deleteButton;
  color: $whiteMain;
  border: 1px solid $deleteButton;

  &:hover {
    background: $errorColor;
  }

  &:disabled {
    background: $errorColor;
  }
}

.padding-0 {
  padding: 0;
  width: fit-content;
  background: transparent;
  border: none;

  &:hover {
    border: none;
    outline: none;
    background: transparent;
  }
}

.error_button {
  border: 1px solid $errorColor;
  color: $errorColor;

  &:hover {
    outline: 1px solid $errorColor;
    color: $errorColor;
  }
}


.typed-buttons-box {
  display: flex;
  gap: 12px;

  button {
    @include flexCenter;
    width: 44px;
    height: 44px;
    border-radius: 8px;
  }

  .passive-btn {
    background: white;
    border: 1px solid $borderColor1;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

    svg {
      path {
        stroke: $textColor8;
      }
    }
  }

  .active-btn {
    border-radius: 8px;
    border: 1px solid $borderColor3;
    background: $borderColor3;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

    svg {
      path {
        stroke: $brandPrimary2;
      }
    }
  }
}


.close-btn {
  @include flexCenter;
  @include resetBtn;
  width: 100%;
  height: 48px;

  color: $textColor8;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $white;
  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  transition: all .2s ease;

  &:hover {
    color: $textColor22;
    border: 1px solid $borderColor1;
    background: $backgroundColor4;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
  }
}

.cancel_btn_modal {
  @include flexCenter;
  width: 100%;
  height: 48px;
  color: $textColor4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;

  border-radius: 8px;
  border: 1px solid $borderColor1;
  background: $whiteMain;
  box-shadow: 0 1px 2px rgba(10, 13, 18, 0.05);

  transition: all .2s;
}

.close-button-box {
  @include flexCenter;
  width: 30px;
  height: 30px;
  border-radius: 32px;
  border: none;
}