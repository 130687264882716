@import "../../../assets/styles/global";

.recommendations-page {
  min-height: 100vh;
  background: $white;

  .recommendations-wrapper {
    @include containerWithPadding;
    padding-bottom: 0 !important;

    .recommendations-page-subtitle {
      color: $textColor5;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 12px;

      @media (min-width: $tablet) {
        font-size: 18px;
        font-weight: 400;
      }

      @media (min-width: $desktop) {
        font-size: 20px;
        margin-top: 16px;
      }
    }

    .recommendations-tabs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 32px 0;

      @media (min-width: $tablet) {
        gap: 24px;
        padding: 48px 0;
      }

      @media (min-width: $desktop) {
        padding: 64px 0;;
      }

      .tag-box-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        border-radius: 12px;
        background: $backgroundColor5;


        @media (min-width: $desktop) {
          padding: 24px;
        }
      }

      .tag-box-title {
        color: $textColor1;
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24px;

        @media (min-width: $tablet) {
          font-size: 24px;
        }

        @media (min-width: $desktop) {
          margin-bottom: 32px;
        }
      }
    }
  }
}