@import '../../assets/styles/global.scss';

.org-list-wrapper {
  .org-list-mobile-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 1px solid $borderColor6;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

    @media (min-width: $tablet) {
      display: none;
    }

    .org-info-mobile-wrapper {
      border-top: 1px solid $borderColor6;
      padding-top: 16px;

      @media (min-width: $tablet) {
        padding-top: 0;
        border: none
      }
    }
  }

  .org-list-desktop-view {
    width: 100%;
    display: none;

    @media (min-width: $tablet) {
      @include spaceBetween;
      padding: 16px;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid $borderColor6;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      margin-bottom: 16px;
    }
    @media (min-width: $desktop) {
      padding: 16px 20px;
    }
  }

  .org-image-and-info {
    @include flexStart;
    gap: 8px;
    width: 100%;

    @media (min-width: $tablet) {
      gap: 16px;
    }

    .org-image-box {
      @include flexCenter;
      width: 72px;
      height: 72px;
      min-width: 72px;
      border-radius: 8px;
      background: $backgroundColor9;

      @media (min-width: $tablet) {
        width: 114px;
        min-width: 114px;
        height: 114px;
      }
      @media (min-width: $desktop) {
        width: 120px;
        min-width: 120px;
        height: 120px;
      }

      img {
        object-fit: cover;
        width: 72px;
        height: 72px;
        border-radius: 8px;
        @media (min-width: $tablet) {
          width: 114px;
          height: 114px;
        }
        @media (min-width: $desktop) {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  .org-info-wrapper {
    width: 100%;
  }

  .org-view-details-desktop {
    display: block;
    @include flexCenter;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      svg {
        transform: rotate(45deg);
        transition: all .2s;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      transition: all .2s;
    }
  }

  .org-title {
    width: 100%;
    color: $textColor1;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;

    @media (min-width: $tablet) {
      font-size: 18px;
    }

    @media (min-width: $desktop) {
      margin-bottom: 16px;
      font-size: 18px;
    }
  }

  .org-info-box {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @media (min-width: $desktop) {
      margin-bottom: 4px
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: #414651;
      }
    }

    a {
      color: $textColor1;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-decoration: none;
      margin-left: 8px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;


      @media (min-width: $desktop) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .address-link {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 0 !important;
  }

  .org-info-box:last-of-type {
    margin-bottom: 0;
  }

  .org-view-details {
    height: 44px;

    a {
      width: 100%;
      height: 100%;
      @include flexCenter;
      color: $textColor8;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      text-decoration: none;
      white-space: nowrap;
      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      padding: 10px 18px;
      transition: all .2s;

      @media (min-width: $tablet) {
        padding: 10px 16px;
      }
    }

    &:hover {
      a {
        color: $textColor22;
        border-radius: 8px;
        border: 1px solid $borderColor1;
        background: $backgroundColor4;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        transition: all .2s;
      }
    }
  }

}


.edit-org-modal {
  .suggest-titles-icon {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $borderColor6;


    .suggest-svg-block {
      width: 48px;
      min-width: 48px;
      height: 48px;
      border-radius: 10px;
      border: 1px solid $borderColor11;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      padding: 12px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .suggest-title_description {
      .edit-org-modal-title {
        color: $textColor1;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      .edit-org-modal-description {
        color: $textColor2;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
      }
    }
  }


  .edit-org-modal-box {
    margin-top: 24px;

    .edit-org-tabs {
      button {
        text-transform: capitalize;
        color: $textColor9;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .Mui-selected {
        color: $brandPrimary2;
      }

      .MuiTabs-indicator {
        background-color: $brandPrimary2;
      }
    }

  }

  .org-images-section {
    max-width: 616px;
    width: 100%;

    .upload-logo-wrapper {
      width: 100%;
      height: 165px;
      padding: 20px 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      border: 1px dashed $borderColor13;
      background: $backgroundColor7;
    }

    .upload-logo-image-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }

      .text-black {
        color: $textColor1;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin: 4px 0;
      }

      .text-green {
        color: #49B776;
      }

      .paragraph-box {
        color: $textColor2;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      .uploaded-logo {
        @include flexCenter;
        width: 64px;
        height: 64px;
        border-radius: 60px;
        border: 2px solid $backgroundColor2;
        padding: 2px;
        margin-bottom: 24px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 60px;
        }
      }

      .no-uploaded-logo {
        width: 80px;
        height: 80px;
        margin-bottom: 8px;
      }

      .no-uploaded-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .remove-logo {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #f07379;
          border: none;
          background: inherit;
        }

        .change-logo {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #49b776;
          border: none;
          background: inherit;
        }
      }
    }

  }


  .edit-org-btns {
    @include alignCenter;
    gap: 16px;
    margin-top: 40px;
  }

}

.subtitle-box {
  margin: 24px 0 16px;

  .subtitle {
    color: $textColor1;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .paragraph {
    color: $textColor2;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 4px;
  }
}


// Hourse Wrapper Style.
.hourse-wrapper {
  width: 100%;
  //margin-top: 8px;
  //border-radius: 8px;
  //background-color: $white;

  @media (min-width: $tablet) {
    width: 736px;
  }

  .wrapper-title {
    color: black;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 24px;
  }

  .hourse-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: auto;
    padding: 2px;
    overflow: hidden;
    max-width: 800px;
    overflow-y: auto;

    .hours-card {
      @include spaceBetween;
      align-items: flex-start;
      gap: 16px;
      padding: 20px 12px;
      border-radius: 12px;
      border: 1px solid $borderColor6;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

      @media (min-width: 360px) {
        flex-direction: column;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        padding: 20px 24px;
      }

      &.hours-card-with_times {
        flex-direction: column;
      }

      .week-day {
        width: 40px;
        color: $textColor2;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
      }

      .add-hours {
        @include resetBtn;
        @include flexCenter;
        gap: 8px;
        color: $brandPrimary2;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;

        @media (min-width: $tablet) {
          margin-left: auto;
        }
      }

      .time-box {
        width: 100%;
        @include spaceBetween;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $borderColor6;

        @media (max-width: 767px) {
          flex-direction: column;

        }

        .hors-checkbox-block {
          @include alignCenter;
          gap: 24px;

          @media (max-width: $tablet) {
            width: 100%;
            flex-direction: column;
            gap: 0;
          }
        }

        .hourse-form {
          width: 100%;
          @include alignCenter;

          @media (max-width: 767px) {
            justify-content: space-between;
          }

          input {
            width: 180px;
            height: 42px;

            color: $textColor1;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            padding: 10px 12px;
            border-radius: 8px;
            border: 1px solid $borderColor1;
            background: $white;
            box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

            @media (max-width: $tablet) {
              width: 100%;
            }

            &:focus {
              outline: none;
              border-radius: 8px;
              box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05), 0px 0px 0px 4px #F5F5F5;
            }
          }
        }


        .fourse-submit {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0 0 0 16px;

          @media (max-width: 767px) {
            margin-top: 16px;
          }

          .remove-btn {
            order: 1;
            padding-right: 0;
          }

          .check-box {
            order: 0;
            margin-left: 0;
          }
        }
      }

      .remove-btn {
        @include resetBtn;
        color: #BB3240;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 0 22px;
      }


      .remove-btn {
        @include resetBtn;

        svg {
          filter: brightness(0) saturate(100%) invert(25%) sepia(100%) saturate(1777%) hue-rotate(345deg) brightness(86%) contrast(100%);
        }
      }

      .time-input {
        border: 1px solid #49b776;
        border-radius: 4px;
        padding: 2px 5px;

        & .MuiInputBase-root::after,
        & .MuiInputBase-root::before {
          content: revert !important;
        }

        & .Mui-disabled {
          color: #bebebe;
        }
      }

      .small-line {
        margin: 0 8px;
        color: $brandPrimary2;
      }

      .check-box {

        .available-text {
          color: $textColor2;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .more-hours {
        @include resetBtn;
        @include flexCenter;
        color: $brandPrimary2;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

.reviews-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .connect-box {
    width: 100%;
    height: 48px;
    @include flexCenter;

    .connect-box-icon_input {
      width: 100%;
      height: 100%;
      @include flexCenter;

      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $white;
      overflow: hidden;

      &.connect-box-icon_input-fill {
        border: 1px solid $borderColor3;

        .social-icon {
          border-right: 1px solid $borderColor3;
          background: $borderColor3;

          img {
            filter: brightness(0) saturate(100%) invert(27%) sepia(81%) saturate(915%) hue-rotate(316deg) brightness(95%) contrast(104%);
          }
        }
      }

      .social-icon {
        height: 100%;
        width: 48px;
        @include flexCenter;
        border-right: 1px solid $borderColor1;

        img {
          filter: brightness(0) saturate(100%) invert(68%) sepia(4%) saturate(314%) hue-rotate(170deg) brightness(96%) contrast(98%);
        }
      }

      .social-input {
        width: 100%;
        height: 100%;

        color: $textColor1;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        padding: 10px 14px;
        border: none;
        outline: none;

        &::placeholder {
          color: $textColor9;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .social-delete {
      height: 100%;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-delete {
        @include resetBtn;

        svg {
          filter: brightness(0) saturate(100%) invert(29%) sepia(92%) saturate(1230%) hue-rotate(338deg) brightness(89%) contrast(109%);
        }
      }
    }
  }
}


.svg-filter {
  filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(71deg) brightness(114%) contrast(100%);
}