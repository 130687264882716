@import "../../../assets/styles/global";

.privacy-terms-page {
  @include containerWithPadding;
  min-height: 100vh;

  .privacy-terms-title-box {
    display: flex;
    align-items: center;
    gap: 12px;

    div {
      width: 14px;
      height: 34px;
      margin-right: 16px;
      background: $brandPrimary;
    }
  }


  .privacy-term-body-box {
    background: white;
    border-radius: 4px;
    color: #222222CC;
    line-height: normal;

    p{
      line-height: normal;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      unicode-bidi: isolate;
    }

    ul{
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      unicode-bidi: isolate;
    }

    li {
      list-style-type: disc !important;
      color: $inputPlaceholder;
    }

    @media (min-width: 320px) {
      margin: 24px 0;
      padding: 16px
    }
    @media (min-width: 768px) {
      margin: 32px 0;
      padding: 32px
    }
    @media (min-width: 1240px) {
      margin: 32px 0;
      padding: 32px
    }
    @media (min-width: 1920px) {
      margin: 40px 0;
      padding: 48px
    }
  }

  .privacy-title {
    font-size: 20px;
    color: black;
  }

  .privacy-sub-title {
    font-size: 16px;
    color: black;
    font-weight: bold;
  }

  .privacy-title-small {
    font-size: 18px;
    color: black;
  }

  .privacy-title-small-black {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
}