.loader-wrapper {
  display: flex;
}

.page-loader {
  height: 100vh;

  .page-loader-box {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}