@import "../../assets/styles/global";

.search-box {
  @include flexCenter;
  gap: 8px;
  width: 100%;
  height: 44px;

  @media (max-width: $tablet) {
    height: auto;
    flex-direction: column;
    align-items: end;
  }

  .search-box-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $borderColor1;
    background: $whiteMain;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    padding: 12px 16px;
    //transition: all .3s;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 12px;
      border: none;
      box-shadow: none;
      padding: 0;
    }


    input:first-of-type {
      border-right: 1px solid $textColor6;

      @media (max-width: $tablet) {
        border: none;
      }
    }

    .search-box-input {
      margin-right: 12px;

      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }

    .search-box-inputs-wrapper {
      @include flexCenter;
      width: 100%;
      @media (max-width: $tablet) {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $borderColor1;
        background: $whiteMain;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        padding: 12px 16px;
      }
    }

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 0 8px;

    }

    input::placeholder {
      color: $textColor6;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .search-box-icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;

      &.search-box-search {
        path {
          fill: $brandPrimary;
        }
      }
    }
  }

  .search-button-box {
    @include resetBtn;
    height: 100%;
    border-radius: 8px;
    border: 1px solid $borderColor3;
    background: $borderColor3;
    padding: 12px;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    transition: all .2s;

    &:hover {
      background: $brandPrimary;
      transition: all .2s;

      svg {
        transform: rotate(45deg);
        transition: all .2s;

        path {
          stroke: white;
        }
      }
    }

    svg {
      transition: all .2s;
    }
  }
}


.search-layout-box {
  max-width: 712px;
  margin: 16px auto 0;
}

.search-by-name-wrapper {
  min-height: 200px;
  max-height: 300px;
  width: 283px;
  margin-top: 6px;
  box-shadow: 0 2px 6px #0000001A;
  overflow: auto;
  padding: 15px 16px;
  border-radius: 6px;
  background: #FFF;
  margin-left: 0px;
  @media (min-width: $tablet) {
    margin-top: 18px;
    margin-left: -15px;
  }


}

.searched-card-box-link {
  text-decoration: none;
  color: black;
}

.searched-card-box {
  @include alignCenter;
  gap: 16px;
  height: 48px;
  margin-bottom: 4px;
  cursor: pointer;

  .searched-card-image {
    @include flexCenter;
    width: 30px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(195, 195, 195, 0.50);
    border-radius: 4px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .searched-card-info {
    width: 100%;
  }

  .searched-card-title {
    font-size: 16px;
    color: black;
  }

  .searched-card-desc {
    font-size: 14px;
    color: #717171;
    margin-top: 4px;
  }
}

.suggestion-item {
  margin-top: 12px;
}

.current-location-btn {
  @include alignCenter;
  @include resetBtn;
  gap: 8px;
  font-size: 16px;

  div {
    width: 24px;
    height: 22px;
  }
}

.suggestion-text {
  font-size: 16px;
  color: black;
  margin-bottom: 12px;
  cursor: pointer;
}
