@import "../../../assets/styles/global";

.home-page {
  background: $white;

  .discover-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    max-width: 1440px;

    @media (min-width: $desktop) {
      flex-direction: row;
      height: 630px;
    }

    .search-wrapper {
      width: 100%;
      height: 100%;
      @include flexCenter;
      padding: 16px;
      background: url("../../../assets/images/img/headerBannerMap.png") no-repeat center center;

      @media (min-width: $tablet) {
        padding: 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 64px;
      }

      .search-box-banner {
        height: fit-content;
      }

      .search-box-title {
        color: $textColor1;
        font-family: Poppins,sans-serif !important;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;

        @media (min-width: $tablet) {
          font-size: 32px;
          line-height: 42px;
        }
        @media (min-width: $desktop) {
          font-size: 40px;
          line-height: 52px;
        }
      }


      .search-box-description {
        color: $textColor2;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 20px 0 24px;

        @media (min-width: $tablet) {
          font-size: 18px;
          margin: 16px 0 24px 0;
        }
        @media (min-width: $desktop) {
          margin: 12px 0 20px;
        }
      }
    }

    .slider-wrapper-box {
      width: 100%;
      height: 100%;
      @media (min-width: $desktop) {
        width: 50%;
      }
    }

    .slider-wrapper {
      width: 100%;
      height: 300px;

      display: flex;
      align-items: flex-end;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 16px;
      gap: 10px;
      flex: 1 0 0;

      @media (min-width: $tablet) {
        width: 100%;
        height: 300px;
      }
      @media (min-width: $desktop) {
        width: 720px;
        height: 630px;
      }
    }

    .liner-done {
      height: 3px;
      border-radius: 8px;
      background: #000000;
    }

    .liner-dont-done {
      height: 3px;
      border-radius: 8px;
      background: #C6C6C6;
    }

    .slider-types-box {
      height: 92px;
      width: 100%;
      @include alignCenter;
      border-radius: 12px;
      background: $backgroundColor4;
      box-shadow: 0 20px 26px 0 rgba(0, 0, 0, 0.05), 0 8px 9px 0 rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(15px);
      padding: 24px;
      gap: 24px;

      .slider-type {
        width: 100%;
        color: $backgroundColor11;
        font-family: Poppins, sans-serif !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        text-align: center;

        p {
          margin-bottom: 13px;
        }
      }

      button {
        @include resetBtn;
        min-width: 24px;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .tags-box {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 16px;

      .tag {
        @include resetBtn;
        @include flexCenter;

        color: $textColor20;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        border-radius: 16px;
        background: $backgroundColor5;
        padding: 4px 12px;
      }

      .tag:last-child {
        margin-right: 0;
      }
    }
  }


  // About Business section

  .about-business-section {
    padding: 64px 0;
    background-color: $white;

    @media (min-width: $desktop) {
      padding: 96px 0;
    }

    .about-card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (min-width: $tablet) {
        gap: 48px;
      }

      @media (min-width: $desktop) {
        gap: 32px;
        flex-direction: row;
      }
    }

    .container {
      padding: 0 16px;

      @media (min-width: $tablet) {
        padding: 0 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 80px;
      }
    }

    .about-card-box {
      text-align: center;
      width: 100%;

      @media (min-width: $tablet) {
      }
      @media (min-width: $desktop) {
      }

      .title {
        font-size: 18px;
        color: $textColor1;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-weight: 600;
        line-height: 30px;
        margin: 16px 0 8px;

        @media (min-width: $tablet) {
          margin: 24px 0 16px;
          font-size: 20px;
        }

        @media (min-width: $desktop) {
          margin: 20px 0 8px;
        }
      }

      .description {
        color: $textColor2;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .link-btn {
        width: fit-content;
        margin: 16px auto 0;

        @media (min-width: $tablet) {
          margin: 20px auto 0;
        }

        a {
          @include alignCenter;
          justify-content: center;
          gap: 8px;
          color: $textColor2;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-decoration: none;
        }
      }
    }

    .about-card-box:last-of-type {
      margin-right: 0;
    }
  }

  // Upcoming events section

  .upcoming-events-section {
    padding: 24px 0;

    @media (min-width: $tablet) {
      padding: 64px 0;
    }
    @media (min-width: $desktop) {
      padding: 64px 0;
    }

    .upcoming-events-title {
      max-width: 1440px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 16px;

      @media (min-width: $tablet) {
        padding: 0 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 80px;
      }


      a {
        margin: 0 8px;
      }

      svg {
        width: 20px;
        height: 20px;
        display: none;

        @media (min-width: $tablet) {
          width: 40px;
          height: 40px;
          display: block;
        }
        @media (min-width: $desktop) {
          width: 55px;
          height: 55px;
        }
      }

      button {
        @include resetBtn;
        color: $textColor1;
        font-family: Poppins, sans-serif !important;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.48px;
        text-decoration: underline;

        @media (min-width: $tablet) {
          font-size: 28px;
          font-weight: 600;
          letter-spacing: -0.56px;
        }
        @media (min-width: $desktop) {
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.72px;
        }
      }
    }

    .upcoming-events-sub-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }

    .tab-and-calendar {
      width: 100%;
      max-width: 1440px;
      display: flex;
      gap: 12px;
      margin: 12px auto 0 auto;
      padding: 0 16px;
      flex-direction: column-reverse;
      align-items: flex-start;

      @media (min-width: $tablet) {
        padding: 0 40px;
        margin: 32px auto 0 auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      @media (min-width: $desktop) {
        padding: 0 80px;
        margin: 40px auto 0 auto;

      }
    }



    .upcoming-events-box {
      width: 100%;
      max-width: 1380px;
      position: relative;
      padding: 0 16px;
      margin: 0 auto;

      @media (min-width: $tablet) {
        padding: 0 40px;
      }

      @media (min-width: $desktop) {
        padding: 0 50px;
      }

      .slide-arrow-button {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid $borderColor6;
        background: $white;
        padding: 8px;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

        @media (min-width: $desktop) {
          @include flexCenter;
        }

        svg {
          filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(1099%) hue-rotate(181deg) brightness(97%) contrast(92%);
        }

        &.slide-arrow-button_left {
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        &.slide-arrow-button_right {
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        &.disabled-slide-arrow-button {
          cursor: auto;

          svg {
            filter: brightness(0) saturate(100%) invert(76%) sepia(0%) saturate(2176%) hue-rotate(305deg) brightness(89%) contrast(89%);
          }
        }
      }

      .react-multi-carousel-list {
        background: transparent;

        .react-multi-carousel-track {
          display: flex;

          .react-multi-carousel-item {
            padding: 24px 8px;

            @media (min-width: $tablet) {
              padding: 40px 8px;
            }

            @media (min-width: $desktop) {
              padding: 64px 8px;
            }

            &:first-child {
            }

            &:last-child {
            }
          }
        }
      }
    }

    .upcoming-events-not-yet{
      @include flexCenter;
      height: 200px;
      color:  #181D27;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media (min-width: $tablet) {
        height: 400px;
      }
      //@media (min-width: $desktop) {
      //  height: 400px;
      //}
    }

    .brows-all-block {
      display: flex;
      justify-content: end;
      width: 100%;
      max-width: 1440px;
      padding: 0 16px;
      margin: 0 auto;

      @media (min-width: $tablet) {
        padding: 0 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 80px;
      }

      .brows-all-upcoming {
        height: 44px;
        @include flexCenter;
        color: $white;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        border-radius: 8px;
        border: 1px solid $brandPrimary;
        background: $brandPrimary;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
        padding: 12px 20px;
        background: $brandPrimary;

        @media (min-width: $desktop) {
          height: 48px;
        }

        &:hover {
          background: $brandPrimary2;
          transition: all .2s;
        }
      }
    }
  }


  .tag-tabs-box {
    @include alignCenter;
    gap: 8px;
    overflow-x: auto;
    padding-bottom: 10px;
    width: 90vw;
    @media (min-width: $tablet) {
      width: 90%;
      //width: calc(100vw - 300px) !important;
    }


    button {
      @include resetBtn;
      height: 44px;
      padding: 10px 16px;
      color: $textColor9;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
      text-transform: capitalize;
      white-space: nowrap;
      min-width: fit-content;
      @media (min-width: $tablet) {
        padding: 0 24px;
      }

      @media (min-width: $desktop) {
        padding: 0 24px;
        height: 48px;
      }
    }

    button:hover {
      background: $backgroundColor4;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    }

    .active-tab {
      background: $borderColor3;
      color: $brandPrimary2;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
    }

    .passive-tab {
      background: transparent;
      color: $textColor9;
    }
  }

  //  Benefits Section

  .benefits-section {
    background-color: white;

    .benefits-section-box {
      padding: 48px 0;
      @media (min-width: $tablet) {
        padding: 64px 0;
      }
      @media (min-width: $desktop) {
        padding: 96px 0;
      }

      .benefit-titles-svg_box {
        @include spaceBetween;
        gap: 8px;

        @media (min-width: $tablet) {
          gap: 24px;
        }

        @media (min-width: $desktop) {
          gap: 446px;
        }

        .benefit-title-dec-box {
          .benefit-title-svg-block {
            display: flex;
            gap: 8px;
            margin-bottom: 12px;

            @media (min-width: $desktop) {
              margin-bottom: 20px;
            }

            .benefit-title {
              color: $textColor1;
              font-family: Poppins, sans-serif !important;
              font-size: 22px;
              font-weight: 600;
              letter-spacing: -0.44px;
              line-height: normal;

              @media (min-width: $tablet) {
                font-size: 24px;
                letter-spacing: -0.48px;
              }

              @media (min-width: $desktop) {
                font-size: 36px;
                letter-spacing: -0.72px;
              }
            }

            .benefit-svg-mobile {
              min-width: 40px;
              width: 40px;
              height: 40px;

              @media (min-width: $tablet) {
                display: none;
              }
            }
          }

          .benefit-description {
            color: $textColor2;
            font-weight: 400;
            line-height: 24px;
            font-size: 16px;

            @media (min-width: $desktop) {
              font-size: 20px;
              line-height: 30px;
            }
          }
        }

        .benefit-svg {
          display: none;

          @media (min-width: $tablet) {
            display: block;
            min-width: 76.813px;
            height: 80.001px;
          }

          @media (min-width: $desktop) {
            min-width: 115.216px;
            height: 119.999px;
          }
        }
      }

      .benefit-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 24px;

        @media (min-width: $tablet) {
          margin-top: 48px;
          gap: 20px;
        }


        @media (min-width: $desktop) {
          margin-top: 60px;
          gap: 40px;
        }

        .benefit-cards-box {
          width: 100%;
          display: flex;
          gap: 16px;
          padding: 16px;
          background: $backgroundColor7;
          cursor: pointer;
          transition: all .2s;

          @media (min-width: $tablet) {
            padding: 20px;
            width: calc(100% / 2 - 20px);
          }

          &:hover {
            border-radius: 8px;
            background: $backgroundColor2;
            transition: all .2s;

            .benefit-card-title {
              color: $textColor10;
              transition: all .2s;
            }

            .benefit-card-desc {
              color: $brandPrimary2;
              transition: all .2s;
            }
          }

          .benefit-card-title {
            color: $textColor1;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;

            @media (min-width: $tablet) {
              font-size: 20px;
            }
          }

          .benefit-card-desc {
            color: $textColor2;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 8px;
          }

          .benefit-card-svg {
            min-width: 48px;
            width: 48px;
            height: 48px;

            @media (min-width: $tablet) {
              min-width: 40px;
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }

    .personalized-section-box {
      padding: 48px 0;

      @media (min-width: $tablet) {
        padding: 64px 0;
      }

      @media (min-width: $desktop) {
        padding: 80px 0;
      }

      .personalized-section-title {
        color: $textColor1;
        font-family: Poppins, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;

        @media (min-width: $tablet) {
          font-size: 28px;
          line-height: 40px;
          letter-spacing: -0.56px
        }

        @media (min-width: $desktop) {
          font-size: 30px;
          font-weight: 600;
          line-height: 38px;
        }
      }

      .personalized-section-desc {
        color: $textColor5;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @media (min-width: $tablet) {
          font-size: 20px;
        }

        @media (min-width: $desktop) {
          font-size: 20px;
          line-height: 30px
        }
      }

      .personalized-tags-show-button {
        display: flex;
        margin-top: 16px;

        @media (min-width: $tablet) {
          margin-top: 24px;
        }
      }
    }
  }

  //  Support Section

  .support-arm-organization {
    padding: 48px 0;
    background-color: white;

    @media (min-width: $tablet) {
      padding: 64px 0;
    }

    @media (min-width: $desktop) {
      padding: 96px 0;
    }

    .support-arm-header {
      @include spaceBetween;
      gap: 10px;
    }

    .container {
      padding: 0 16px;

      @media (min-width: $tablet) {
        padding: 0 40px;
      }
      @media (min-width: $desktop) {
        padding: 0 80px;
      }
    }

    .support-org-slider {
      padding: 32px 0;

      @media (min-width: $tablet) {
        padding: 40px 0;
      }

      @media (min-width: $desktop) {
        padding: 48px 0;
      }
    }

    .support-org-card {
      display: flex !important;
      flex-direction: column-reverse;
      background: $borderColor7;
      border-radius: 8px;
      overflow: hidden;
      height: auto;

      @media (min-width: $desktop) {
        flex-direction: row;
        height: 365px;
      }
    }

    .supprot-org-info {
      width: 100%;
      height:inherit;
      padding: 20px;
      border-radius: 8px 8px 0 0;
      background: $backgroundColor7;
      overflow: auto;

      @media (min-width: $tablet) {
        padding: 24px;
      }
      @media (min-width: $desktop) {
        padding: 40px 64px;
      }
    }

    .supprot-org-info-title_svg {
      @include spaceBetween;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 12px;
      @media (min-width: $tablet) {
        margin-bottom: 16px;
      }

      .support-org-title {
        color: $textColor1;
        font-family: Poppins, sans-serif !important;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;

        @media (min-width: $tablet) {
          font-size: 20px;
        }
        @media (min-width: $desktop) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      .supprot-org-info-button_svg {
        @include resetBtn;
        @include resetBtn;
        min-width: 24px;
        width: 24px;
        height: 24px;

        &:hover {
          svg {
            transform: rotate(45deg);
            transition: all .2s;
          }
        }

        svg {
          filter: brightness(0) saturate(100%) invert(9%) sepia(6%) saturate(2678%) hue-rotate(177deg) brightness(99%) contrast(94%);
          transition: all .2s;
        }
      }
    }


    .support-org-desc {
      color: $textColor2;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: $tablet) {
        font-size: 16px;
      }
      @media (min-width: $desktop) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    .support-org-categories-wrapper {
      @include alignCenter;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 16px;
      height: auto;
      overflow: auto;

      @media (min-width: $desktop) {
        margin-top: 26px;
        height: 56px;
      }

      .support-org-categories {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        div {
          color: $textColor21;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          padding: 2px 10px;
          border-right: 16px;
          background: $backgroundColor12;
        }
      }
    }


    .support-org-footer {
      @include spaceBetween;
      gap: 24px;
      border-top: 1px solid $borderColor6;
      padding: 8px 0;

      button {
        @include resetBtn;
        @include flexCenter;
        gap: 8px;

        color: $textColor2;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

    }
  }

  //  Business Near You

  .business-near-you-wrapper {
    background: $backgroundColor7;

    .business-near-you {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      padding: 48px 16px;

      @media (min-width: $tablet) {
        padding: 64px 40px;
        gap: 40px;
      }

      @media (min-width: $desktop) {
        flex-direction: row;
        gap: 64px;
        padding: 80px;
      }

      .business-near-you-title {
        color: $textColor1;
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
        @media (min-width: $tablet) {
          font-size: 28px;
        }
        @media (min-width: $desktop) {
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.72px;
        }
      }

      .business-near-you-desc {
        color: $textColor2;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @media (min-width: $desktop) {
          margin-bottom: 32px;
        }
      }

      .business-near-you-info {
        width: 100%;
        @media (min-width: $desktop) {
          min-width: 544px;
          width: 544px;
        }
      }

      .business-near-you-cards {
        width: 100%;
        @media (min-width: $desktop) {
          width: 50%;
        }
      }

      .business-near-you-categories {
        margin-bottom: 24px;
      }

      .business-near-you-organizations {
        height: 500px;
        overflow: auto;
      }

      .desktop-view {
        display: none;
        @media (min-width: $desktop) {
          display: block;
        }
      }

      .mobile-view {
        display: block;
        @media (min-width: $desktop) {
          display: none;
        }
      }
    }
  }

  .skeleton-card{
    width: 100%;
    opacity: 0.3;
    height: 156px;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid $borderColor6;
  }
}

//.home-section-title {
//  color: #000;
//  font-size: 24px;
//  font-weight: 600;
//  line-height: normal;
//  @media (min-width: $tablet) {
//    font-size: 32px;
//    line-height: 60px;
//  }
//  @media (min-width: $desktop) {
//    font-size: 48px;
//    line-height: 60px;
//  }
//}

.home-section-title {
  color: $textColor1;
  font-family: Poppins, sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.48px;

  @media (min-width: $tablet) {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.56px;
  }
  @media (min-width: $desktop) {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
}


//.org-supported-slider-wrapper-box{
//  width: 50%;
//  height: 100%;
//}

.org-supported-slider-box {
  width: 100%;
  min-width: 100%;
  height: 260px;
  @media (min-width: $tablet) {
    width: 100%;
    min-width: 100%;
    height: 300px;
  }
  @media (min-width: $desktop) {
    width: 500px;
    min-width: 500px;
    height: 394px;
  }
}

.org-supported-slider-wrapper-box {
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 200px;
  border-radius: 0 0 8px 8px;
  //background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: $tablet) {
    width: 100%;
    min-width: 100%;
    height: 300px;
  }
  @media (min-width: $desktop) {
    width: 500px;
    min-width: 500px;
    height: 365px;
  }

  .org-slider-types-box {
    position: absolute;
    bottom: 24px;
    left: 16px;
    right: 16px;
    @include alignCenter;
    height: 48px;
    padding: 16px;
    gap: 4px;
    border-radius: 8px;
    background: $backgroundColor4;
    box-shadow: 0 20px 26px 0 rgba(0, 0, 0, 0.05), 0 8px 9px 0 rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(15px);

    @media (min-width: $tablet) {
      height: 56px;
    }

    .slider-type {
      width: 100%;
      color: #3D3D3D;
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
      text-align: center;

      p {
        margin-bottom: 13px;
      }
    }

    button {
      @include resetBtn;
      width: 28px;
      height: 28px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.slider-skeleton-loader{
  width: 100%;
  height: 300px!important;

  @media (min-width: $tablet) {
    width: 100%;
    height: 300px!important;
  }
  @media (min-width: $desktop) {
    width: 720px;
    height: 630px!important;
  }
}