@import "../../../assets/styles/global";

.events-page {
  @include containerWithPadding;
  min-height: 100vh;

  .event-filters-box {
    .filter-box {
      @include spaceBetween;
      margin: 24px 0;
      @media (min-width: 768px) {
        margin: 40px 0;
      }
    }

    .filter-selector-box {
      overflow: scroll;
      width: auto;
      display: flex;
      gap: 8px;
    }
  }

  .filter-desktop-view {
    display: none;
    @media (min-width: 1279px) {
      display: block;
    }
  }

  .filter-mobile-view {
    display: block;
    @media (min-width: 1279px) {
      display: none;
    }
  }

  //  Event Grid

  .event-grid {
    width: 340px;
    margin: 0 auto;
    @media (min-width: $tablet) {
      width: 700px;
    }
    @media (min-width: $desktop) {
      width: 100%
    }
    @media (min-width: $xl) {
      width: 100%
    }
  }

  .event-grid-section {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 16px;

  }

  .org-events-box {
    margin-top: 40px;
  }
}



.org-slider-container {
  width: 100%;
  max-width: 1380px;
  position: relative;
  //padding: 0 16px;
  margin: 0 auto;

  @media (min-width: $tablet) {
    //padding: 0 40px;
  }

  @media (min-width: $desktop) {
    //padding: 0 50px;
  }

  .slide-arrow-button {
    display: none;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid $borderColor6;
    background: $white;
    padding: 8px;
    box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);

    @media (min-width: $desktop) {
      @include flexCenter;
    }

    svg {
      filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(1099%) hue-rotate(181deg) brightness(97%) contrast(92%);
    }

    &.slide-arrow-button_left {
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &.slide-arrow-button_right {
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &.disabled-slide-arrow-button {
      cursor: auto;

      svg {
        filter: brightness(0) saturate(100%) invert(76%) sepia(0%) saturate(2176%) hue-rotate(305deg) brightness(89%) contrast(89%);
      }
    }
  }

  .react-multi-carousel-list {
    background: transparent;

    .react-multi-carousel-track {
      display: flex;

      .react-multi-carousel-item {
        padding: 16px 8px;

        @media (min-width: $tablet) {
          padding: 24px 8px;
        }

        &:first-child {
        }

        &:last-child {
        }
      }
    }
  }


  .slick-track {
    width: 100% !important;
    //display: flex;
  }

  .slick-prev {
    left: -45px;
  }

  .slick-next {
    right: -15px;
  }

  .slider-container {
    .nuka-wrapper {
      gap: 16px;
    }
  }

}

//   Event Map View

.events-page-map-view {
  height: 100vh;
  position: relative;
}

.event-map-view-section {
  height: 1000px;

  .map-event-wrapper {
    width: 328px;
    border-radius: 16px;

    .map-event-image-box {
      background-repeat: no-repeat;
      height: 160px;
      border-radius: 8px 8px 0 0;
      background-size: cover;
      background-color: white;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .map-event-type-box {
      width: fit-content;
      padding: 5px 10px;
      border-radius: 4px;
      background: #FFF;
      color: #1E2A38;
      font-size: 10px;
      font-weight: 500;
    }

    .close-map-btn {
      @include flexCenter;
      @include resetBtn;
      width: 20px;
      height: 20px;
      padding: 5.367px 5.277px;
      border-radius: 32px;
      background: $backgroundColor6;
    }

    .map-event-information-box {
      padding: 12px;
    }

    .map-event-title {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 27px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .map-event-date-time-location-box {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 12px;
    }

    .map-event-info-box {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      p {
        color: $textColor1;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      .your-time {
        font-weight: 600;
      }

      .icon-box {
        width: 20px;

        svg {
          path {
            stroke: $textColor4;
          }
        }
      }
    }

    .map-event-view-details-btn {
      width: fit-content;
      @include flexCenter;
      @include resetBtn;
      gap: 8px;
      color: $brandPrimary2;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-decoration: none;
      margin: 16px 0 0 auto;

      &:hover {
        svg {
          transform: rotate(45deg);
          transition: all .2s;
        }
      }

      svg {
        transition: all .2s;
      }
    }
  }

  .gm-style {
    .gm-style-iw-c {
      padding: 0;
      max-width: 328px !important;
    }

    .gm-style-iw-chr {
      display: none !important;
    }
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
}

.filter-actions-selector-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .MuiRadio-root {
    padding: 0;
    color: black;
  }

  .Mui-checked {
    color: black;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
    color: black;
  }

  .search-city {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 12px;
    border-radius: 4px;
    border: 1px solid #D0D2D7;
    background: #FFF;
    margin-bottom: 8px;
    height: 32px;

    input {
      border: none;
      background: transparent;
      width: 100%;
      height: 100%;
      color: #222;
      outline: none;
    }
  }

  .rdrDateRangePickerWrapper {
    margin-top: 14px;
    margin-left: -16px;
  }

}

.filter-radio-actions-box, .filter-radio-actions-box-type {
  @include flexCenter;
  justify-content: flex-start;
  @include resetBtn;
  width: 100%;
  padding: 10px 12px;
  color: $textColor8;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &.filter-radio-actions-box-active {
    background: $backgroundColor2;
  }

  &:hover {
    width: 100%;
    padding: 10px 12px;
    color: $textColor8;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background: $backgroundColor4;
  }
}

.filter-radio-actions-box-type {
  justify-content: space-between;
}

.not-yet-screen-wrapper {
  @include flexCenter;
  width: 100%;
  min-height: 50vh;

  @media (min-width: $tablet) {
    min-height: 60vh;
  }
  @media (min-width: $tablet) {
    min-height: 70vh;
  }

  .not-yet-screen-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .not-yet-img-block {
    width: 122px;
    height: 104px;
    margin-bottom: 24px;

    @media (min-width: $tablet) {
      width: 158px;
      height: 135px;
      margin-bottom: 32px;
    }
    @media (min-width: $desktop) {
      width: 188px;
      height: 160px;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }


  p {
    color: $textColor12;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;

    @media (min-width: $tablet) {
      font-size: 20px;
      margin-bottom: 12px;
    }

    @media (min-width: $desktop) {
      font-size: 24px;
    }
  }

  span {
    width: 100%;
    color: $textColor13;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (min-width: $tablet) {
      width: 50%;
      font-size: 18px;
    }
  }
}