@import '../../assets/styles/global.scss';


.stripe-card-box {
  width: 100%;
  max-width: 340px;
  @media (min-width: $tablet) {
    max-width: 540px;
    width: 540px;
  }
}

.add-card-title {
  color: $textColor1;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.add-card-description {
  color: $textColor2;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 8px 0 16px;

  @media (min-width: $tablet) {
    margin: 8px 0 20px;
  }
}

.checkoutForm-wrapper {
  .checkoutForm-svg {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid $borderColor11;
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    padding: 12px;
    margin-bottom: 16px;

    @media (min-width: $tablet) {
      margin-bottom: 20px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .checkoutForm-label {
    color: $textColor4;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    .checkoutForm-input {
      width: 100%;
      height: 44px;

      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      border-radius: 8px;
      border: 1px solid $borderColor1;
      background: $white;
      box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      padding: 12px 16px;
      margin-top: 6px;

      @media (min-width: $tablet) {
        height: 48px;
      }

      &:hover {
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05);
      }

      &:active, &:focus {
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05), 0 0 0 4px $backgroundColor6;
        outline: none;
      }

      &.StripeElement--focus {
        box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 0.05), 0 0 0 4px $backgroundColor6;
        outline: none;
      }


    }

    .checkoutForm-input-error {
      border: 1px solid $errorColor;

      &.StripeElement--focus {
        outline: 1px solid $errorColor;
      }

      &:hover {
        outline: 1px solid $errorColor;
      }
    }
  }

  .cardholder-block, .credit-card-number, .credit-card-name,
  .credit-card-expiry-date, .credit-card-cvv {
    width: 100%;
  }

  .checkoutForm-wrapper-box {
    display: flex;
    flex-direction: column;
    gap: 0;

    @media (min-width: $tablet) {
      flex-direction: row;
      gap: 16px;

    }

    .name-number-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media (min-width: $tablet) {
        width: 75%;
      }
    }

    .expiry-date-cvv {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;

      @media (min-width: $tablet) {
        width: 35%;
        flex-direction: column;
        gap: 0;
      }
    }
  }

  .checkbox-text-block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    @media (min-width: $tablet) {
      margin-bottom: 32px;
    }

    .checkbox-text {
      color: $textColor2;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      .checkbox-text-link {
        color: $textColor2;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        margin-left: 6px;
      }
    }
  }

  .card-errors {
    color: $errorColor;
    font-size: 12px;
    font-weight: 400;
    min-height: 18px;
    margin-left: 15px;
    margin-top: 2px;
  }
}

.card-action-btns {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: $tablet) {
    flex-direction: row;
    gap: 16px;;
  }
}
