@import "../../assets/styles/global";

.check-password-wrapper {
  width: 100%;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: max-height 0.15s ease-out;

  &.active {
    max-height: 1000px;
    visibility: visible;
    opacity: 1;
    transition: max-height 0.25s ease-in;
    margin-top: 10px;
  }

  .check-password-item {
    display: flex;
    align-items: center;
    gap: 8px;

    .check-password-message {
      color: $brandPrimary;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 300;

      &.check-message-success {
        color: $textColor2;
      }
    }

    .check-password-item-icon {
      width: 18px;
      height: 18px;
      min-width: 18px;

      .check-password-item-icon-success {
        path {
          fill: #12B76A;
        }

        rect {
          fill: #D1FADF;
        }
      }
    }
  }
}